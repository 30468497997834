import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';
import { UpdateUserModalComponent } from './update-user.component';

@NgModule({
  declarations: [UpdateUserModalComponent],
  entryComponents: [UpdateUserModalComponent],
  providers: [TranslatePipe],
  imports: [CommonModule, SharedModule],
  exports: [UpdateUserModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UpdateUserModalModule {}
