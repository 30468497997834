import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  styleUrls: ['./button.component.scss'],
  templateUrl: './button.component.html',
})
export class ButtonComponent {
  @Input()
  public txtButton: string;
  @Input()
  public disabled: boolean = false;
  @Input()
  public loading: boolean = false;
  @Input()
  public type: string;
  @Input()
  public color: string;

  @Input()
  public hoverDisable: boolean;

  @Input()
  public hasIcon: string;

  @Output() clickButton: EventEmitter<void> = new EventEmitter();

  constructor() {
    this.disabled = false;
    this.type = 'button';
    this.color = 'black';
    this.hoverDisable = false;
    this.hasIcon = '';
    this.txtButton = '';
  }
}
