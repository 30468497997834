<aside class="aside">
  <nav>
    <ul>
      <li *ngFor="let item of items">
        <a
          *hasRole="item.roles"
          id="items-main"
          (click)="openList(item)"
          class="item-active"
          routerLinkActive="active"
          #route="routerLinkActive"
          [routerLink]="item.path"
          (click)="toMainMenu(item)">
          <div class="images">
            <img [alt]="item.path" [src]="item.img" *ngIf="item.showImg" />
            {{ item.name | translate }}
          </div>
        </a>

        <div
          routerLinkActive="children"
          #router="routerLinkActive"
          *ngFor="let children of item?.childrens"
          [ngClass]="getClass()">
          <ng-container *hasRole="item.roles">
            <a class="children-active" [routerLink]="children.path">
              <div class="images">
                <img
                  [alt]="children.path"
                  [src]="children.img"
                  *ngIf="children.showImg" />
                {{ children.name | translate }}
              </div>
            </a>
          </ng-container>
        </div>
      </li>
    </ul>
    <div class="configuration-section" *ngIf="showConfiguration">
      <div
        class="configuration-section__item"
        (click)="selectConfigSubmenu(showConfiguration)"
        [routerLink]="navigateConfiguration()">
        <img alt="configuration" src="assets/images/configuration-icon.svg" />
        <p>{{ "ASIDE.CONFIGURATION" | translate }}</p>
      </div>
      <div class="configuration-section__item" (click)="logout()">
        <img alt="logout" src="assets/images/logout-icon.svg" />
        <p>{{ "ASIDE.LOG_OUT" | translate }}</p>
      </div>
    </div>
  </nav>
</aside>
