import { Component } from '@angular/core';
import { Toast } from 'ngx-toastr';
import { toastAnimations } from './toast.animations';

@Component({
  selector: '[toast-component]',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [toastAnimations.flyInOut],
  preserveWhitespaces: false,
})
export class ToastComponent extends Toast {
  undoString = 'undo';

  action(event: Event) {
    event.stopPropagation();
    this.undoString = 'undid';
    this.toastPackage.triggerAction();
    return false;
  }
}
