export enum RoutingPathsEnum {
  INITIAL_RELATIVE = '',
  WILDCARD = '**',

  AUTH = 'auth',
  LOGIN = 'login',

  // USER MODULE
  USERS = 'users',
  USERS_DETAIL = 'detail',
  USER_EDIT = ':userId/edit',

  // REPORTS MODULE
  REPORTS = 'reports',

  // CONFIGURATION MODULE
  COST_COMMISSIONS = 'cost-commissions',
  LEVELS = 'levels',
  UDIS = 'udis',
  CONFIGURATION = 'configuration',
}

export enum MenuPathsEnum {
  REPORTS = '/reports',
  USERS = '/users',
  USERS_EDIT_FULL = '/users/:userId/edit',
  COST_COMMISSIONS_FULL = '/configuration/cost-commissions',
  LEVELS_FULL = '/configuration/levels',
  UDIS_FULL = '/configuration/udis',
  LOGIN_FULL = '/auth/login',
}
