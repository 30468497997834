import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export enum NotificationType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

export type NotificationState = {
  type: NotificationType;
  title: string;
  message: string;
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public state: Subject<NotificationState> = new BehaviorSubject(null);

  public success(title: string, message: string): void {
    this.state.next({
      type: NotificationType.success,
      title,
      message,
    });
  }

  public warning(title: string, message: string): void {
    this.state.next({
      type: NotificationType.warning,
      title,
      message,
    });
  }

  public error(title: string, message: string): void {
    this.state.next({
      type: NotificationType.error,
      title,
      message,
    });
  }

  public info(title: string, message: string): void {
    this.state.next({
      type: NotificationType.info,
      title,
      message,
    });
  }

  public getState(): Observable<NotificationState> {
    return this.state
      .asObservable()
      .pipe(filter((state: NotificationState) => !!state));
  }
}
