import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {
  @Input() public label: string;
  @Input() public disable: boolean;
  @Input() public id: string;
  @Input() public name: string;
  @Input() public size: string;
  @Input() public colorCheck: string;
  @Input() public checked: boolean;
  @Input() public errorRequired: string;
  @Output() public selected = new EventEmitter();

  public control!: NgControl;

  public iconPassword: string;

  constructor(@Inject(Injector) private injector: Injector) {
    this.label = 'label';
    this.disable = false;
    this.id = '';
    this.name = '';
  }

  public ngOnInit(): void {
    this.control = this.injector.get(NgControl);
  }

  public onTouched: Function = () => {};

  public onChange: Function = (ev: any) => {
    this.value = ev;
  };

  protected _valueLocal: boolean;

  public get value() {
    return this.checked;
  }

  public get invalid(): boolean {
    return false;
  }

  public get touched(): boolean {
    return true;
  }

  @Input() set value(v) {
    if (v !== this._valueLocal) {
      this._valueLocal = v;
      this.onChange(v);
    }
  }
  public changeCheckbox(ev): void {
    this.checked = ev.target.checked;
    this.selected.emit(ev.target.checked);
  }
  public onBlur(): void {
    this.onTouched();
  }

  public writeValue(value: boolean): void {
    this.value = value;
  }

  public registerOnChange(fn: (data: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
