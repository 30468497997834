import { MenuPathsEnum } from '@shared/enums/routing-paths.enum';
import { MenuItem } from '../models';

export const MENU: MenuItem[] = [
  {
    name: 'ASIDE.REPORTS',
    img: 'assets/images/reports-icon.svg',
    path: MenuPathsEnum.REPORTS,
    showModule: true,
    showImg: true,
    roles: ['ADMIN_ROLE'],
  },
  {
    name: 'ASIDE.USERS',
    path: MenuPathsEnum.USERS,
    img: 'assets/images/users-icon.svg',
    showModule: true,
    showImg: true,
    roles: ['ADMIN_ROLE', 'CUSTOMER_SUPPORT_ROLE'],
  },
];

export const MENU_SECONDARY: MenuItem[] = [
  {
    name: 'Atrás',
    path: MenuPathsEnum.REPORTS,
    img: 'assets/images/back.svg',
    showModule: true,
    backToMain: true,
    showImg: true,
    roles: [],
  },
  {
    name: 'Costos y comisiones',
    path: MenuPathsEnum.COST_COMMISSIONS_FULL,
    showModule: true,
    showImg: false,
    roles: ['ADMIN_ROLE'],
  },
  {
    name: 'Niveles',
    path: MenuPathsEnum.LEVELS_FULL,
    showModule: true,
    showImg: false,
    roles: ['ADMIN_ROLE'],
  },
  {
    name: 'UDIS',
    path: MenuPathsEnum.UDIS_FULL,
    showModule: true,
    showImg: false,
    roles: ['ADMIN_ROLE'],
  },
];
