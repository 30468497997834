import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  public get(
    pathname: string,
    options?: {
      headers?: any;
      params?: any;
      observe?: any;
      responseType?: any;
    },
  ): Observable<any> {
    return this.http.get(`${environment.GATEWAY_URL}${pathname}`, options);
  }

  public post(
    pathname: string,
    body: any,
    options?: {
      headers?: any;
      params?: any;
      observe?: any;
      responseType?: any;
    },
  ): Observable<any> {
    return this.http.post(
      `${environment.GATEWAY_URL}${pathname}`,
      body,
      options,
    );
  }

  public put(pathname: string, body: any, headers?): Observable<any> {
    return this.http.put(`${environment.GATEWAY_URL}${pathname}`, body, {
      headers: headers,
    });
  }

  public delete(pathname: string, params?): Observable<any> {
    return this.http.delete(`${environment.GATEWAY_URL}${pathname}`, params);
  }

  public deleteWithBody(pathname: string, body: any): Observable<any> {
    return this.http.request(
      'DELETE',
      `${environment.GATEWAY_URL}${pathname}`,
      {
        body,
      },
    );
  }
}
