<section class="modal">
  <div class="modal-content">
    <form class="modal-body" [formGroup]="nipForm">
      <div class="close-modal">
        <h1>{{ "DETAIL_CARD.TITLE" | translate }}</h1>
        <img
          (click)="close(false)"
          src="assets/images/close.svg"
          alt="close-icon" />
      </div>
      <div class="body-content">
        <div class="modal-section">
          <h2>{{ "DETAIL_CARD.SUBTITLE" | translate }}</h2>
          <div class="modal-section-info">
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_CARD.COLUMNS.ID" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ data.card.id | translate }}
              </div>
            </div>
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_CARD.COLUMNS.TYPE" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ data.card.type }}
              </div>
            </div>
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_CARD.COLUMNS.STATUS" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ data.card.status }}
              </div>
            </div>
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_CARD.COLUMNS.LAST_FOUR" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ data.card.lastFour }}
              </div>
            </div>
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_CARD.COLUMNS.PROVIDER" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ data.card.provider }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body-content" *hasRole="['ADMIN_ROLE']">
        <div class="modal-section">
          <h2>{{ "DETAIL_CARD.NIP_TITLE" | translate }}</h2>
          <div class="modal-section-info-row__second">
            <app-input
              formControlName="nip"
              [label]="'DETAIL_CARD.FIELDS.NIP_FIELD' | translate"
              [inputStyle]="'rounder-input'"
              [inputMask]="'0000'">
            </app-input>
            <app-button-select
              [txtButton]="'DETAIL_CARD.BUTTONS.CHANGE_NIP'"
              [loading]="loading"
              (buttonClick)="onSaveButton()"></app-button-select>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
