import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { head } from 'lodash';

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [CommonModule, FormsModule, CalendarModule],
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent implements ControlValueAccessor, OnInit {
  @Input() public id: string;
  @Input() public placeholder: string;
  @Input() public disable: boolean;
  protected _value = '';
  @ViewChild('calendar') public calendar: Calendar;

  public control!: NgControl;
  public maxDate: Date = new Date();
  private openWithIcon: boolean = false;

  constructor(
    @Inject(Injector) public injector: Injector,
    private component: ElementRef,
  ) {
    this.disable = false;
    this.placeholder = '';
  }

  ngOnInit(): void {
    this.control = this.injector.get(NgControl);
  }

  @HostListener('document:click', ['$event.target'])
  clickOnPage(targetElement: HTMLElement): void {
    let isCalendarClass: boolean = false;
    targetElement.classList.forEach(val => {
      const pickerClasses = ['p-datepicker', 'p-monthpicker', 'p-yearpicker'];
      if (pickerClasses.some(name => val.includes(name))) {
        isCalendarClass = true;
      }
    });

    const datepickerClass: HTMLCollectionOf<Element> =
      document.getElementsByClassName(`p-datepicker p-component`);

    const datepicker: Element = head(datepickerClass);
    if (datepicker) {
      datepicker.className =
        !this.component.nativeElement.contains(targetElement) &&
        !isCalendarClass
          ? 'hidden-calendar p-datepicker p-component'
          : 'p-datepicker p-component';
    }
  }

  public openCalendar(): void {
    document.getElementById('range').click();
    this.openWithIcon = !this.openWithIcon;
  }

  public onSelect(): void {
    const values = this.control.value;

    if (values.length > 0 && !values.includes(null)) {
      this.calendar.overlayVisible = false;
    }
  }

  public inputDate(): void {
    this.openWithIcon = true;
  }

  public get value() {
    return this._value;
  }

  @Input() set value(v) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  /**
   * ControlValueAccessor method
   * @param ev
   */
  public writeValue(value: string): void {
    this.value = value;
  }

  /**
   * ControlValueAccessor method
   * @param ev
   */
  public onChange: Function = (ev: any) => {};
  public registerOnChange(fn: (data: any) => void): void {
    this.onChange = fn;
  }

  /**
   * ControlValueAccessor method
   * @param ev
   */
  public onTouched: Function = () => {};
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
