import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ChangeUdisModalComponent } from './change-udis.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';

@NgModule({
  declarations: [ChangeUdisModalComponent],
  entryComponents: [ChangeUdisModalComponent],
  providers: [TranslatePipe],
  imports: [CommonModule, SharedModule],
  exports: [ChangeUdisModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChangeUdisModalModule {}
