import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@core/services';
import { UserActionsEnum } from '@shared/enums';

export interface UpdateUserModal {
  userAction: UserActionsEnum;
  userId: string;
  fullName: string;
  status: string;
}

@Component({
  selector: 'app-update-user-modal',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserModalComponent implements OnInit {
  @Input() data: UpdateUserModal;
  @Output() public readonly closed: EventEmitter<boolean> = new EventEmitter();
  public loading = false;
  public form: FormGroup;
  private userAction: UserActionsEnum = UserActionsEnum.ACTIVATE;

  public constructor(
    private formBuilder: FormBuilder,
    private usersService: UserService,
  ) {
    this.form = this.formBuilder.group({
      password: [Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    if (this.data) {
      this.userAction = this.data.userAction;
    }
  }

  public close(callService: boolean = false): void {
    if (callService && this.userAction == UserActionsEnum.DELETE) {
      this.loading = true;
      this.usersService.delete(this.data.userId).subscribe({
        next: res => {
          this.closed.emit(callService);
          this.closed.complete();
        },
        error: err => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
    } else if (callService && this.userAction == UserActionsEnum.ACTIVATE) {
      this.loading = true;
      this.usersService.enable(this.data.userId).subscribe({
        next: res => {
          this.closed.emit(callService);
          this.closed.complete();
        },
        error: err => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
    } else if (callService && this.userAction == UserActionsEnum.DEACTIVATE) {
      this.loading = true;
      this.usersService.disable(this.data.userId).subscribe({
        next: res => {
          this.closed.emit(callService);
          this.closed.complete();
        },
        error: err => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
    } else {
      this.closed.emit(callService);
      this.closed.complete();
    }
  }

  public get title(): string {
    return `MODALS.USERS.${this.userAction}`;
  }
}
