import { Injectable, Injector, OnDestroy } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StateService } from '../state';
import { ModalDefaults } from '@shared/enums/modal.enum';
import { ChangeUdisModalComponent } from '@core/modals/change-udis/change-udis.component';
import { UpdateUserModalComponent } from '@core/modals/update-user/update-user.component';
import { TransactionDetailsModalComponent } from '@core/modals/transaction-details/transaction-details.component';
import { StateName } from '@shared/enums';
import { CostumerDocumentsComponent } from '@core/modals/costumer-documents/costumer-documents.component';
import { CardDetailsModalComponent } from '@core/modals/card-details/card-details.component';

@Injectable({ providedIn: 'root' })
export class ModalsService implements OnDestroy {
  public modal;
  private readonly unsubscribe: Subject<void>;
  private activeModal: HTMLElement;

  public constructor(private readonly stateService: StateService) {
    this.unsubscribe = new Subject();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public getModalActive(): HTMLElement {
    return this.activeModal;
  }

  public closeAll(): void {
    if (this.activeModal) {
      document.body.removeChild(this.activeModal);
    }
  }

  public registerModals(injector: Injector): Promise<void> {
    this.stateService.set(StateName.modal_service, undefined);
    console.warn('ModalsService - listenChannels', []);

    return new Promise((resolve, reject) => {
      try {
        this.createCustomElements(injector);

        this.stateService
          .state(StateName.modal_service)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((msg: { modal; message; payload }) => {
            let modal;
            if (!msg) {
              resolve();
              return;
            }
            modal = this.createModal(msg.modal);
            modal.data = msg.payload;
            if (!document.getElementsByTagName(msg.modal).item(0)) {
              document.body.appendChild(modal);
            }
          });

        resolve();
      } catch (e) {
        resolve();
      }
    });
  }

  private createCustomElements(injector: Injector): void {
    try {
      customElements.define(
        ModalDefaults.MODAL_CHANGE_UDIS,
        createCustomElement(ChangeUdisModalComponent, { injector }),
      );

      customElements.define(
        ModalDefaults.MODAL_UPDATE_USER,
        createCustomElement(UpdateUserModalComponent, { injector }),
      );

      customElements.define(
        ModalDefaults.MODAL_TRANSACTION_DETAILS,
        createCustomElement(TransactionDetailsModalComponent, { injector }),
      );

      customElements.define(
        ModalDefaults.MODAL_CARD_DETAILS,
        createCustomElement(CardDetailsModalComponent, { injector }),
      );

      customElements.define(
        ModalDefaults.MODAL_CUSTOMER_DOCUMENTS,
        createCustomElement(CostumerDocumentsComponent, { injector }),
      );
    } catch (e) {
      console.warn('Modales cargados previamente', []);
    }
  }

  private createModal(selector: string): HTMLElement {
    let modal;
    if (!document.getElementsByTagName(selector).item(0)) {
      modal = document.createElement(selector);

      modal.addEventListener('closed', () => {
        document.body.removeChild(modal);
        this.activeModal = null;
      });

      this.activeModal = modal;
    }
    return this.activeModal;
  }
}
