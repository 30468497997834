import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';
import { CardDetailsModalComponent } from './card-details.component';
import { LocalizeDatePipe } from '@shared/pipes';

@NgModule({
  declarations: [CardDetailsModalComponent],
  entryComponents: [CardDetailsModalComponent],
  providers: [TranslatePipe, LocalizeDatePipe],
  imports: [CommonModule, SharedModule],
  exports: [CardDetailsModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CardDetailsModalModule {}
