<section class="modal">
  <div class="modal-content">
    <form class="modal-body" [formGroup]="form">
      <div class="close-modal">
        <img
          (click)="close(false)"
          src="assets/images/close.svg"
          alt="close-icon" />
      </div>
      <div class="body-content">
        <div class="modal-body__title">
          <p *ngIf="!save">
            {{ "CONFIGURATION.CHANGE_UDI" | translate }}{{ data?.udi?.value }}
          </p>
          <p *ngIf="save">{{ "CONFIGURATION.CONFIRM" | translate }}</p>
        </div>

        <div class="modal-body__input">
          <app-input-fieldset
            *ngIf="!save"
            [type]="'number'"
            [label]="'CONFIGURATION.LABEL_UDI' | translate"
            formControlName="udis"
            [errorRequired]="'CONFIGURATION.VALIDATIONS.UDI' | translate">
          </app-input-fieldset>

          <app-input-fieldset
            *ngIf="save"
            [errorServiceText]="'AUTH.VALIDATIONS.PASSWORD_INVALID' | translate"
            [label]="'AUTH.LABELS.PASSWORD' | translate"
            formControlName="password_authorize"
            type="password"
            [placeholder]="'AUTH.PLACEHOLDERS.PASSWORD' | translate"
            [errorRequired]="'AUTH.VALIDATIONS.PASSWORD' | translate">
          </app-input-fieldset>
        </div>

        <div class="modal-body__information" *ngIf="!save">
          <img src="assets/images/warning.svg" alt="" />
          <p>{{ "CONFIGURATION.INFORMATION_TEXT" | translate }}</p>
        </div>
        <div class="modal-body__buttons">
          <ng-container *ngIf="!save">
            <app-button
              class="button"
              [txtButton]="'CONFIGURATION.SAVE' | translate"
              idSelect="button-udi-accept"
              (clickButton)="saveUdi()"></app-button>
            <app-button
              class="button"
              [txtButton]="'CONFIGURATION.CANCEL' | translate"
              color="unfilled"
              idSelect="button-udi-cancel"
              (clickButton)="close(false)"></app-button>
          </ng-container>
          <ng-container *ngIf="save">
            <app-button
              class="autorize"
              [txtButton]="'CONFIGURATION.AUTHORIZE' | translate"
              idSelect="button-udi-accept"
              (clickButton)="saveUdiWithPassword()"
              [loading]="loading"></app-button>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
</section>
