<button
  (click)="clickButton.emit()"
  [type]="type"
  id="{{ hoverDisable.toString() }}"
  class="{{ color }}"
  [ngClass]="{ disable: disabled || loading }"
  class="button--loading">
  <img *ngIf="hasIcon" [src]="hasIcon" alt="sync_invoice" />
  <span *ngIf="!loading">{{ txtButton }}</span>
  <div *ngIf="loading" class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</button>
