import { Component, Input } from '@angular/core';
import { Breadcrumb } from '@shared/interfaces';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent {
  @Input() url: Breadcrumb[];
  @Input() ico: string;
}
