import { IModel, IStateConfig } from './global';
import { ModelData } from './models/model-data.model';
import { Observable } from 'rxjs';

export class StateModelManager {
  // tslint:disable-next-line: no-any
  private static window = window as any;

  private config: IStateConfig;
  // tslint:disable-next-line: no-any
  private store: Map<string, IModel<any>>;

  public static getInstance(config: IStateConfig): StateModelManager {
    if (!this.window.sm) {
      this.window.sm = new StateModelManager(config);
    }

    return this.window.sm;
  }

  public constructor(config: IStateConfig) {
    this.config = config || ({} as IStateConfig);
    this.store = new Map();
  }

  public clear(): boolean {
    if (this.store) {
      this.store.clear();

      return true;
    }

    return false;
  }

  public createModel<T>(initialData?: T | any): ModelData<T> {
    return new ModelData<T>(initialData);
  }

  public get<T>(storeName: string): T | undefined {
    if (!this.store.has(storeName)) {
      console.warn(`Store ${storeName} not create`);

      return undefined;
    }

    return (this.store.get(storeName) as IModel<T>).getValue();
  }

  public has(storeName: string): boolean {
    return this.store.has(storeName);
  }

  public remove(storeName: string): boolean {
    return this.store.delete(storeName);
  }

  public set<T>(storeName: string, value: T): boolean {
    if (!this.store.has(storeName)) {
      console.warn(`Store ${storeName} not create`);

      this.create(storeName);
    }

    (this.store.get(storeName) as IModel<T>).set(value);

    return true;
  }

  public state<T>(storeName: string): Observable<T> | undefined {
    if (!this.store.has(storeName)) {
      console.warn(`Store ${storeName} not create`);

      return undefined;
    }

    return (this.store.get(storeName) as IModel<T>).state;
  }

  private create<T>(storeName: string, initialData?: T | any): void {
    const model = new ModelData<T>(initialData);

    this.store.set(storeName, model);
  }
}
