<div class="calendar-section" id="date-picker-{{ id }}">
  <p-calendar
    [id]="id"
    #calendar
    dateFormat="dd-mm-yy"
    [readonlyInput]="false"
    inputId="range"
    [selectionMode]="'range'"
    [maxDate]="maxDate"
    inputId="range"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (onSelect)="onSelect()"
    (click)="inputDate()"
    [showOnFocus]="true">
  </p-calendar>
  <img
    (click)="openCalendar()"
    src="assets/images/calendar.svg"
    alt="calendar" />
</div>
