import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  template: ` <router-outlet></router-outlet> `,
})
export class AppComponent {
  constructor(
    private config: PrimeNGConfig,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.translateService
      .get('primeng')
      .subscribe(res => this.config.setTranslation(res));
  }

  title = 'cashmind';
}
