export enum StateName {
  remember_user = 'remember_user',
  aside_close = 'aside_close',
  menu_change = 'menu_change',
  user_permissions = 'user_permissions',
  user_roles = 'user_roles',
  change_hamburger_menu_state = 'change_hamburger_menu_state',
  modal_service = 'modal_service',
  user_authenticated = 'user_authenticated',
}
