import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { Card } from '@shared/models/user/card.model';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@core/services';
import { UserData } from '@shared/models';

@Component({
  selector: 'app-card-details-modal',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss'],
})
export class CardDetailsModalComponent implements OnDestroy, OnInit {
  public nipForm: FormGroup;
  public loading;
  private destroy$: Subject<void> = new Subject<void>();
  @Input() public data: {
    card: Card;
    user: UserData;
  };
  @Output() public readonly closed: EventEmitter<boolean> = new EventEmitter();

  public constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private readonly userService: UserService,
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  private buildForm(): void {
    this.nipForm = this.formBuilder.group({
      nip: [
        null,
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
    });
  }

  public close(callService: boolean = false): void {
    this.closed.emit(callService);
    this.closed.complete();
  }

  public onSaveButton(): void {
    const cardId = this.data.card.id;

    const nipData = {
      ...this.nipForm.getRawValue(),
    };

    this.loading = true;
    this.userService
      .changeCardPin(this.data.user.id, cardId, nipData.nip)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        complete: () => {
          this.toastr.show('', 'NIP cambiado correctamente', {
            closeButton: true,
          });
          this.loading = false;
        },
        error: err => {
          this.toastr.success(
            '',
            err?.error?.data?.length ? err.error?.data[0] : err?.error?.data,
            {
              toastClass: 'toast-error',
              messageClass: 'toast-message',
              titleClass: 'toast-title',
              closeButton: true,
            },
          );
          this.loading = false;
        },
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
