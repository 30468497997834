import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Chart, ChartData } from 'chart.js/auto';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements AfterViewInit {
  public myChart;
  @ViewChild('myChart') chartCanvas: ElementRef;

  @Input() data: ChartData;

  ngAfterViewInit(): void {
    this.myChart = new Chart(this.chartCanvas.nativeElement, {
      type: 'pie',
      data: this.data,
    });
  }
}
