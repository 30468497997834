<label *ngIf="!!label">{{ label }}</label>
<div
  id="content-pagination-table"
  [ngClass]="{ disabled: disabled, error: error }"
  class="caja">
  <div class="container" (click)="openList()">
    <div class="line">{{ itemSelected }}</div>
    <img
      [ngClass]="getClassArrow()"
      src="assets/images/filters-arrow.svg"
      alt="arrow" />
  </div>
  <ul id="list-select-pagination" [ngClass]="getClass()">
    <li
      class="text"
      *ngFor="let opt of options; let i = index"
      [id]="i"
      (click)="selectValue(opt)">
      {{ opt }}
    </li>
  </ul>
</div>
