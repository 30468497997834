import { BehaviorSubject, Observable } from 'rxjs';
import { IModel } from '../global';

export class ModelData<T> implements IModel<T> {
  private model: BehaviorSubject<T>;
  private model$: Observable<T>;

  public constructor(initialValue: T) {
    this.model = new BehaviorSubject(initialValue);
    this.model$ = this.model.asObservable();
  }

  public get state(): Observable<T> {
    return this.model$;
  }

  public getValue(): T {
    return this.model.getValue();
  }

  public set(data: T): void {
    this.model.next(data);
  }
}
