import { NgForOfContext } from '@angular/common';
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { Paginate } from '@shared/constants';
import { CurrencyType } from '@shared/enums';
import { Types } from '@shared/interfaces';
import { IDataHeader } from '../table.types';

@Component({
  selector: 'app-table',
  templateUrl: './paginated-table.component.html',
  styleUrls: ['./paginated-table.component.scss'],
})
export class PaginatedTableComponent implements OnChanges {
  @ViewChildren('arrow') arrow: QueryList<ElementRef>;
  @ViewChildren('up') up: QueryList<ElementRef>;
  @ViewChildren('check') check: QueryList<ElementRef>;
  @ViewChildren('down') down: QueryList<ElementRef>;

  @Input() headers: IDataHeader[];
  @Input() body;
  @Input() typeDetail: string;
  @Input() selected = [];
  @Input() showPagination = true;
  @Input() loading: boolean = false;
  @Input() showPaginationSection: boolean = true;
  @Input() textAlign: 'CENTER' | 'LEFT';

  @Output() eventPagination = new EventEmitter();
  @Output() eventSelected = new EventEmitter();
  @Output() eventDetailOpen = new EventEmitter();
  @Output() eventItemsPerPage = new EventEmitter();
  @Output() rowClick = new EventEmitter();

  pages: string[] = Paginate;
  public itemSelected = [];
  @ContentChild(TemplateRef)
  contactTemplate: TemplateRef<NgForOfContext<any>>;

  public CurrencyType = CurrencyType;

  public indiceDetail: number;
  public arrowUp: number;
  public arrowDown: number;

  public showDetail: boolean;
  public showCheckboxSelectItems: boolean;

  constructor() {
    this.showDetail = false;
  }

  ngOnChanges(): void {
    if (this.loading) {
      if (this.up?.length > 0) {
        let arrayArrowsUp: ElementRef[] = [...this.up];
        this.headers.forEach((_, i: number) => {
          if (
            arrayArrowsUp[i] &&
            this.headers[i]?.config?.type !== 'arrow-right-detail'
          ) {
            arrayArrowsUp[i].nativeElement.className = 'filter-active';
          }
        });
      }
      if (this.down?.length > 0) {
        let arrayArrowsDown: ElementRef[] = [...this.down];
        this.headers.forEach((_, i: number) => {
          if (
            arrayArrowsDown[i] &&
            this.headers[i]?.config?.type !== 'arrow-right-detail'
          ) {
            arrayArrowsDown[i].nativeElement.className = 'filter-active';
          }
        });
      }
    }
  }

  public getStylesTableHead(width: string | number, unit?: string) {
    const result = width ? `${width}${unit || '%'}` : 'initial';
    return {
      width: result,
      'min-width': result,
    };
  }

  public onEventPagination({
    page,
    itemsPerPage,
  }: {
    page: number;
    itemsPerPage: number;
  }): void {
    this.eventPagination.emit({ page, itemsPerPage });
  }

  public onItemsPerPage(itemsPerPage: number) {
    this.eventItemsPerPage.emit(itemsPerPage);
  }

  public selectField(
    ev,
    callBack: (ev) => void,
    checkboxSelectItems: string[],
  ) {
    const existField = this.itemSelected.find(item => item === ev);
    if (existField) {
      this.itemSelected = [...this.itemSelected.filter(item => item !== ev)];
    } else {
      this.itemSelected.push(ev);
    }
    callBack(this.itemSelected);
    if (this.itemSelected?.length && checkboxSelectItems?.length) {
      this.showCheckboxSelectItems = true;
    } else {
      this.showCheckboxSelectItems = false;
    }
  }
  public selectAllField(
    ev: any,
    callBack: (ev) => void,
    checkboxSelectItems: string[],
  ) {
    if (ev.target.checked) {
      this.check.forEach((check, i: number) => {
        check.nativeElement.checked = true;
      });
      this.itemSelected = [...this.body?.elements];
    } else {
      this.check.forEach((check, i: number) => {
        check.nativeElement.checked = false;
      });
      this.itemSelected = [];
    }
    callBack(this.itemSelected);
    if (this.itemSelected?.length && checkboxSelectItems?.length) {
      this.showCheckboxSelectItems = true;
    } else {
      this.showCheckboxSelectItems = false;
    }
  }
  public select(item) {
    const existItem = this.selected.find(value => value === item);
    if (!existItem) {
      this.selected.push(item);
    }

    this.eventSelected.emit(this.selected);
  }

  public changeValue(event, item) {
    const checked = event?.target?.checked;
    if (!checked) {
      let index = this.selected?.indexOf(item);
      this.selected?.splice(index, 1);
    }
  }
  public getChecked(item) {
    if (this.selected.includes(item)) {
      return true;
    } else {
      return false;
    }
  }
  public getClassCheckedBorder(item) {
    if (!this.selected.includes(item)) {
      return null;
    }
    return {
      [`checked-border`]: true,
    };
  }

  public getIndex(index) {
    return {
      [`detail`]: true,
      [`detail--show`]: index == this.indiceDetail,
    };
  }

  public getWidthDetails() {
    let colSpan = this.headers?.length + 1;
    return colSpan;
  }

  public clickDetail(index, item) {
    this.showDetail = !this.showDetail;
    if (this.indiceDetail === index) {
      this.arrow[index].nativeElement.className = 'arrow';
      this.indiceDetail = undefined;
    } else {
      this.arrow[index].nativeElement.className = 'inverted';

      this.eventDetailOpen.emit(item);

      this.body.elements.forEach((_, i: number) => {
        if (i !== index) {
          this.arrow[i].nativeElement.className = 'arrow';
        }
      });
      this.indiceDetail = index;
    }
  }

  public filterTable(index, header, order: boolean) {
    header?.order(index, header, order);
    const arrayArrowsUp: ElementRef[] = [...this.up];
    const arrayArrowsDown: ElementRef[] = [...this.down];
    if (!order) {
      this.showAndHiddenVector(
        index,
        arrayArrowsUp,
        arrayArrowsDown,
        this.arrowUp,
      );
      return;
    }
    this.showAndHiddenVector(
      index,
      arrayArrowsDown,
      arrayArrowsUp,
      this.arrowDown,
    );
  }

  public clickShowRightDetail(item) {
    this.eventDetailOpen.emit(item);
  }

  public status(item) {
    if (item.deleted) {
      return 'inactivo';
    }
    if (item.activated && !item.deleted) {
      return 'activo';
    }
    if (!item.activated && !item.deleted) {
      return 'pendiente';
    }
    return item.status;
  }

  private showAndHiddenVector(
    index: number,
    arrayArrowActive,
    arrayArrowInactive,
    arrowSelect: number,
  ) {
    if (arrowSelect === index) {
      arrayArrowActive[index].nativeElement.className = 'filter-active';
    } else {
      arrayArrowActive[index].nativeElement.className = 'filter-disable';
      this.headers.forEach((_, i: number) => {
        if (
          i !== index &&
          arrayArrowActive[i] &&
          this.headers[i]?.config?.type !== 'arrow-right-detail'
        ) {
          arrayArrowActive[i].nativeElement.className = 'filter-active';
        }
      });
    }
    this.headers.forEach((_, i: number) => {
      if (
        arrayArrowInactive[i] &&
        this.headers[i]?.config?.type !== 'arrow-right-detail'
      ) {
        arrayArrowInactive[i].nativeElement.className = 'filter-active';
      }
    });
  }

  selectClassStatus(status): Types {
    switch (status) {
      case 'PAID':
        return {
          color: 'payed',
          text: 'MERCHANT.INVOICE.TYPE_MOVEMENTS.PAYED',
        };

      case 'PENDING':
        return {
          color: 'pending',
          text: 'MERCHANT.INVOICE.TYPE_MOVEMENTS.PENDING',
        };

      case 'EXPIRED':
        return {
          color: 'cancel',
          text: 'MERCHANT.INVOICE.TYPE_MOVEMENTS.EXPIRED',
        };

      case 'NOT_FUNDED':
        return {
          color: 'not-bankable',
          text: 'MERCHANT.INVOICE.TYPE_MOVEMENTS.NOT_BANKABLE',
        };

      case 'FUNDED':
        return {
          color: 'available',
          text: 'MERCHANT.INVOICE.TYPE_MOVEMENTS.BANKABLE',
        };

      default:
        return null;
    }
  }

  selectClassStatusSat(statusSat): Types {
    switch (statusSat) {
      case 0:
        return {
          color: 'cancel',
          text: 'MERCHANT.INVOICE.TYPE_MOVEMENTS_SAT.CANCELLED',
        };

      case 1:
        return {
          color: 'available',
          text: 'MERCHANT.INVOICE.TYPE_MOVEMENTS_SAT.AVAILABLE',
        };

      default:
        return null;
    }
  }

  selectClassStatusContract(statusContract): Types {
    switch (statusContract) {
      case 'CANCELLED':
        return {
          color: 'cancel',
          text: 'MERCHANT.CUSTOMER.CUSTOMER_EDIT_CREATE.CANCEL',
        };

      case 'ACTIVED':
        return {
          color: 'available',
          text: 'MERCHANT.CUSTOMER.CUSTOMER_EDIT_CREATE.ACTIVE',
        };

      default:
        return null;
    }
  }

  public hiddenIcon(ev) {
    return {
      ['hidden-icon-action']: !ev,
    };
  }

  public selectValueCheckBoxItems(
    ev: string,
    callbackCheckBoxSelectItems: (ev) => void,
  ) {
    callbackCheckBoxSelectItems(ev);
  }

  public changeInput(ev, item, callBack: (ev) => void) {
    callBack({ input: ev.value, item });
  }

  public onRowClick(item: any) {
    this.rowClick.emit(item);
  }
}
