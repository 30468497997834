<div class="checkbox">
  <label
    class="container {{ size }}"
    [ngClass]="{ 'disable-check': disable }"
    [class.error_label]="control.touched && control.invalid"
    *ngIf="!!label">
    <span class="checkbox-label-text" [innerHTML]="label"></span>
    <input
      [id]="id"
      [name]="name"
      type="checkbox"
      [checked]="value"
      [(ngModel)]="value"
      (change)="changeCheckbox($event)"
      [disabled]="disable"
      (blur)="onBlur()" />
    <span class="checkmark {{ colorCheck }}"></span>
  </label>
</div>
