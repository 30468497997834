import { Injectable, InjectionToken, Inject, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { StateModelManager } from './state';
import { IStateConfig } from './global';
import { StateName } from '@shared/enums';

export interface IStateConfigService {
  i18nLang: string;
  secretKey: string;
}

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private readonly sb: StateModelManager;

  public constructor(@Inject(STATE_CONFIG) @Optional() config: IStateConfig) {
    this.sb = StateModelManager.getInstance(config);
  }

  public clearAll(): boolean {
    if (!this.sb) {
      throw new Error('Failed get instance of StateModelManager');
    }

    return this.sb.clear();
  }

  public createModel<T>(initialValue: T = {} as T) {
    return this.sb.createModel<T>(initialValue);
  }

  public get<T>(storeName: StateName): T | undefined {
    if (!this.sb) {
      throw new Error('Failed get instance of StateModelManager');
    }

    return this.sb.get<T>(storeName);
  }

  public remove(storeName: StateName): boolean {
    if (!this.sb) {
      throw new Error('Failed get instance of StateModelManager');
    }

    return this.sb.remove(storeName);
  }

  // tslint:disable-next-line: no-any
  public set<T>(storeName: StateName, value: T): boolean {
    if (!this.sb) {
      throw new Error('Failed get instance of StateModelManager');
    }

    return this.sb.set<T>(storeName, value);
  }

  public state<T>(storeName: StateName): Observable<T> | undefined {
    if (!this.sb) {
      throw new Error('Failed get instance of StateModelManager');
    }

    return this.sb.state<T>(storeName);
  }
}

export const STATE_CONFIG = new InjectionToken<IStateConfig>('stateConfig');
