import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, of, switchMap } from 'rxjs';
import { StorageService } from '../services';
import { StorageKey } from '@shared/enums';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return of(this.storageService.get(StorageKey.ACCESS_TOKEN)).pipe(
      switchMap((accessToken: string) => {
        if (accessToken) {
          const cloneRequest = request.clone({
            headers: request.headers.append(
              'Authorization',
              `Bearer ${accessToken}`,
            ),
          });
          return next.handle(cloneRequest);
        }
        return next.handle(request);
      }),
    );
  }
}
