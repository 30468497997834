import { Pipe, PipeTransform } from '@angular/core';
import { Suffixes } from '@shared/constants';

@Pipe({
  name: 'thousandSuff',
  standalone: true,
})
export class ThousandSuffixesPipe implements PipeTransform {
  transform(
    input: string | number,
    args?: string | number,
    currency?: boolean,
  ): number | string {
    let exp: number;
    const suffixes = Suffixes;

    if (Number.isNaN(input)) {
      return null;
    }

    if (Number(input) < 1000) {
      let number: string | number;

      number = this.removeZeros(this.truncNumber(input, 2));

      if (Number(number) < 0) {
        return '-' + (currency ? '$' : '') + Number(number) * -1;
      }
      return (currency ? '$' : '') + number;
    }

    exp = Math.floor(Math.log(Number(input)) / Math.log(1000));

    let numberFormat: string = (Number(input) / Math.pow(1000, exp)).toString();

    numberFormat = this.removeZeros(this.truncNumber(numberFormat, args));

    if (Number(numberFormat) < 0) {
      return '-' + (currency ? '$' : '') + Number(numberFormat) * -1;
    }

    return (currency ? '$' : '') + numberFormat + suffixes[exp - 1];
  }

  public truncNumber(x, args: string | number): string {
    let s = x.toString();
    let l = s.length;
    let decimalLength = s.indexOf('.') + 1;
    args = Number(args);

    if (l - decimalLength <= args) {
      return x.toString();
    }
    let isNeg = x < 0;
    let decimal = x % 1;
    let entera = isNeg ? Math.ceil(x) : Math.floor(x);
    let decimalFormated = Math.floor(Math.abs(decimal) * Math.pow(10, args));
    let finalNum =
      entera + (decimalFormated / Math.pow(10, args)) * (isNeg ? -1 : 1);

    return finalNum.toString();
  }

  public removeZeros(number): string {
    if (number.includes('.')) {
      const decimalIndex: number = number.indexOf('.');
      for (let i = number.length; i > decimalIndex + 1; i--) {
        if (number.toString().endsWith('0') && Number(number[i - 1]) === 0) {
          number = number.slice(0, number.length - 1);
        }
      }
    }

    if (number?.toString().endsWith('.')) {
      number = number.toString().slice(0, number.toString().length - 1);
    }

    return number;
  }
}
