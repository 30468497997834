import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericResponse, ResponseApi, UDI } from 'src/app/shared/models';
import { ApiService } from './api.service';
import { Contract, Restriction } from '@shared/interfaces';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private url = '/v1';
  constructor(private apiService: ApiService) {}

  public getRestrictions(): Observable<ResponseApi<Restriction[]>> {
    return this.apiService.get(`${this.url}/restrictions`);
  }
  public getUdis(): Observable<ResponseApi<UDI>> {
    return this.apiService.get(`${this.url}/udis`);
  }

  public getContracts(
    types: ('INDIVIDUAL' | 'CORPORATIVE')[],
  ): Observable<ResponseApi<Contract[]>> {
    return this.apiService.get(`${this.url}/contract`, { params: { types } });
  }

  public putUdis(
    idUdi,
    body,
    password,
  ): Observable<ResponseApi<GenericResponse>> {
    let headers = new HttpHeaders();
    headers = headers.append('password', password);
    return this.apiService.put(`${this.url}/udis/${idUdi}`, body, headers);
  }
}
