export const COST_COMISSIONS_PROPS = [
  'AccountOpening',
  'ServicePay',
  'AccountManage',
  'AccountInternalSpeiOut',
  'AccountSpeiOut',
  'MinBalance',
  'CardReposition',
  'CardRecharge',
  'CardReposition',
  'Annuity',
  'GenerateBankStatement',
  'Recharge',
];
