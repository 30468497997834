import { Injectable } from '@angular/core';
import {
  GenericResponse,
  ITransactionParams,
  IuserMetricsAgesParams,
  MetricsAges,
  MetricsStates,
  PagedResponse,
  ResponseApi,
  UserData,
  UserParams,
} from '@shared/models';
import { User } from '@shared/models/user/user.model';
import { catchError, Observable, of, throwError } from 'rxjs';
import { ApiService } from './api.service';
import { UserStatus } from '@shared/models/user/user-status.model';
import { UserDocument } from '@shared/models/user/user-document.model';
import { UserBeneficiary } from '@shared/models/user/user-beneficiary.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly url = '/v1/customer';

  constructor(private apiService: ApiService) {}

  public getDocumentsByCustomerId(
    customerId: string,
  ): Observable<ResponseApi<UserDocument[]>> {
    return this.apiService.get(`${this.url}/${customerId}/documents`);
  }

  public getDataById(customerId: string): Observable<ResponseApi<UserData>> {
    return this.apiService.get(`${this.url}/${customerId}`);
  }

  public getMetricsById(
    customerId: string,
    params?: object,
  ): Observable<ResponseApi<any>> {
    return this.apiService.get(`${this.url}/${customerId}/metrics`, { params });
  }

  public getStatusMetrics(
    params?: object,
  ): Observable<ResponseApi<UserStatus[]>> {
    return this.apiService.get(`${this.url}/metrics/status`, { params });
  }

  public put(
    customerId: string,
    body: object,
  ): Observable<ResponseApi<GenericResponse>> {
    return this.apiService.put(`${this.url}/${customerId}`, body);
  }

  public getData(
    params?: UserParams,
  ): Observable<ResponseApi<PagedResponse<User>>> {
    return this.apiService.get(`${this.url}`, { params });
  }

  public getTransactions(customerId: string, params: ITransactionParams) {
    return this.apiService.get(`${this.url}/${customerId}/transaction`, {
      params,
    });
  }

  public getCards(customerId, params: ITransactionParams) {
    return this.apiService.get(`${this.url}/${customerId}/card`, {
      params,
    });
  }

  public changeCardPin(customerId: string, cardId: string, newPin: string) {
    return this.apiService.put(`${this.url}/${customerId}/card/${cardId}/pin`, {
      pin: newPin,
    });
  }

  public downloadCSVFile(params?: ITransactionParams): Observable<any> {
    return this.apiService.get('/v1/customer/download', {
      params,
      observe: 'response',
      responseType: 'blob',
    });
  }

  public downloadPDFFile(customerId: string): Observable<any> {
    return this.apiService.get(`/v1/customer/${customerId}/contract/download`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  public downloadCSVFileById(
    userId: string,
    params?: ITransactionParams,
  ): Observable<any> {
    return this.apiService.get(`/v1/customer/${userId}/download`, {
      params,
      observe: 'response',
      responseType: 'blob',
    });
  }

  public getMetricsAges(
    params?: IuserMetricsAgesParams,
  ): Observable<ResponseApi<MetricsAges[]>> {
    if (params) {
      if (params.startDate) {
        params.startDate = params.startDate?.slice(0, 7);
      }

      if (params.endDate) {
        params.endDate = params.endDate?.slice(0, 7);
      }
    }
    return this.apiService.get(`${this.url}/metrics/ages`, { params });
  }

  public getMetricsStates(
    params?: IuserMetricsAgesParams,
  ): Observable<ResponseApi<MetricsStates[]>> {
    return this.apiService.get(`${this.url}/metrics/state`, { params });
  }

  public delete(customerId: string): Observable<ResponseApi<GenericResponse>> {
    return this.apiService.delete(
      `${this.url}/${customerId}?permanentDelete=true`,
    );
  }

  public getColoniesByZipcode(zipCode: string) {
    return this.apiService.get(`/v1/zipcode/${zipCode}`);
  }

  public enable(customerId: string) {
    return this.apiService.put(`${this.url}/${customerId}/enabled`, {});
  }

  public disable(customerId: string) {
    return this.apiService.delete(
      `${this.url}/${customerId}?permanentDelete=false`,
    );
  }

  public updatePldStatus(customerId: string) {
    return this.apiService.post(
      `${this.url}/${customerId}/update/status/pld`,
      {},
    );
  }

  public getBeneficiaries(
    customerId: string,
  ): Observable<ResponseApi<UserBeneficiary[]>> {
    return this.apiService.get(`${this.url}/${customerId}/beneficiary`);
  }
}
