<section class="modal">
  <div class="modal-content">
    <form class="modal-body">
      <div class="close-modal">
        <h1>{{ "MODALS.COSTUMER_DOCUMENTS.TITLE" | translate }}</h1>
        <div class="buttons">
          <img
            src="assets/images/close.svg"
            alt="close-icon"
            (click)="close(false)" />
        </div>
      </div>
      <div class="body-content">
        <div class="modal-section">
          <div class="document__section" *ngIf="!isLoading">
            <ng-container *ngIf="!!imageFiles.length">
              <img
                *ngFor="let document of imageFiles"
                [src]="document.publicUrl"
                [alt]="document.type"
                class="document__img" />
            </ng-container>

            <ng-container *ngIf="!!pdfFiles.length">
              <a
                target="_blank"
                class="btn__download"
                *ngFor="let document of pdfFiles"
                [href]="document.publicUrl | safe: 'resourceUrl'">
                {{ 'USERS.DOCUMENTS.' + document.type | translate }}
              </a>
            </ng-container>

            <div *ngIf="!imageFiles.length && !pdfFiles.length ">
              <p>{{ "MODALS.COSTUMER_DOCUMENTS.NO_DOCUMENTS" | translate }}</p>
            </div>
          </div>

          <div class="loading__container" *ngIf="isLoading">
            <img
              width="300"
              height="400"
              src="assets/images/loading.gif"
              alt="loading" />
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
