import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import Chart, { ChartConfiguration, ChartDataset } from 'chart.js/auto';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements AfterViewInit {
  private _labels: string[] = [];
  private datasets: ChartDataset[];
  public myChart;
  @ViewChild('myChart') chartCanvas: ElementRef<HTMLCanvasElement>;

  @Input()
  set labels(value: string[]) {
    this._labels.splice(0, this.labels.length, ...value);
  }

  get labels() {
    return this._labels;
  }

  private _chartData: LineChartDataSeries[] = [];

  @Input()
  set chartData(value: LineChartDataSeries[]) {
    this._chartData?.splice(0, this._chartData.length, ...value);
    this.datasets?.splice(0, this.datasets.length, ...this.getDatasets());
    this.myChart?.update();
  }

  get chartData() {
    return this._chartData;
  }

  ngAfterViewInit(): void {
    const chartConfig = this.getChartConfig();

    Chart.defaults.font.size = 10;
    Chart.defaults.font.family = 'Gilroy';
    Chart.defaults.font.weight = 'bold';

    this.myChart = new Chart(
      this.chartCanvas.nativeElement.getContext('2d'),
      chartConfig,
    );
  }

  private getDatasets() {
    return this.chartData.map(({ label, data, color, hidden = false }) => ({
      label,
      data,
      hidden,
      borderColor: color,
      backgroundColor: color,
      ...DATASET_CONFIG,
    }));
  }

  private getChartConfig(): ChartConfiguration {
    this.datasets = this.getDatasets();

    return {
      type: 'line',
      data: {
        datasets: this.datasets,
        labels: this.labels,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          htmlLegend: {
            containerID: 'legend-container',
          },
          legend: {
            display: false,
          },
        },
        scales: {
          y: Y_AXIS_CONFIG,
          x: X_AXIS_CONFIG,
        },
      },
      plugins: [htmlLegendPlugin],
    } as any;
  }
}

const DATASET_CONFIG = {
  tension: 0.2,
  borderWidth: 1,
};

const X_AXIS_CONFIG = {
  title: {
    text: 'Día del mes',
    display: true,
  },
  offset: true,
  grid: {
    drawOnChartArea: false,
    tickColor: '#05A1BA',
    tickLength: -10,
  },
  ticks: {
    padding: 20,
  },
  border: {
    width: 2,
  },
};

const Y_AXIS_CONFIG = {
  title: {
    text: 'No. de Usuarios',
    display: true,
  },
  offset: false,
  beginAtZero: true,
  grid: {
    drawTicks: false,
    lineWidth: 0.5,
  },
  ticks: {
    padding: 10,
    beginAtZero: true,
    callback: function (value) {
      if (value % 1 === 0) {
        return value;
      }
    },
  },
  border: {
    width: 2,
  },
};

type LineChartDataSeries = {
  label: string;
  data: number[];
  color: string;
  hidden: boolean;
};

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = '0';
    listContainer.style.padding = '0';
    listContainer.style.justifyContent = 'space-between';

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach(item => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';
      li.style.fontSize = 'var(--14px-xs)';
      li.style.fontWeight = '600';

      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex),
          );
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px';
      boxSpan.style.display = 'inline-block';
      boxSpan.style.flexShrink = '0';
      boxSpan.style.borderRadius = '30px';
      boxSpan.style.height = '10px';
      boxSpan.style.width = '10px';
      boxSpan.style.marginRight = '10px';

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = 'var(--black)';
      textContainer.style.margin = '0';
      textContainer.style.padding = '0';
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};
