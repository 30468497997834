<div class="statistics">
  <h5 class="statistics__title">{{ title | translate }}</h5>
  <div class="statistics__section">
    <div
      class="statistics__section__column"
      *ngFor="let item of data; let i = index">
      <div class="statistics__section__column__tag">
        <p
          [ngClass]="{ first: i === 0 }"
          class="statistics__section__column__tag__text">
          {{ item?.tag }}
        </p>
      </div>
      <div class="statistics__section__column__quantity">
        <p
          [ngClass]="{ first: i === 0 }"
          class="statistics__section__column__quantity__text">
          {{ item?.quantity | number : "" : "en" }}
        </p>
      </div>
    </div>
  </div>
</div>
