import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';

@Component({
  selector: 'app-input-fieldset',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './input-fieldset.component.html',
  styleUrls: ['./input-fieldset.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFieldsetComponent),
      multi: true,
    },
  ],
})
export class InputFieldsetComponent
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  @ViewChild('inputPassword') public inputPassword: ElementRef | undefined;

  @Input() public type: string;
  @Input() public label: string;
  @Input() public srcIcon: string;
  @Input() public errorRequired: string;
  @Input() public errorPattern: string;
  @Input() public errorCompare: string;
  @Input() public errorServiceText: string;
  @Input() public placeholder: string;
  @Input() public autocomplete: boolean | string;
  @Input() public inputLine: boolean;
  @Input() public maskCurrency: boolean;
  @Input() public disable: boolean;
  @Input() public errorService: boolean;
  @Input() public genericError: boolean;
  @Input() public genericErrorText: string;
  @Input() public errorMinMax: string;
  @Input() public acceptFiles: string;
  @Output() public readonly changePdf: EventEmitter<Event> = new EventEmitter();

  public control!: NgControl;

  public iconPassword: string;

  constructor(@Inject(Injector) private injector: Injector) {
    this.type = 'text';
    this.label = 'label';
    this.maskCurrency = false;
    this.srcIcon = '';
    this.disable = false;
    this.inputLine = false;
    this.iconPassword = 'assets/images/icon-eye.svg';
    this.placeholder = '';
    this.errorService = false;
    this.autocomplete = true;
    this.acceptFiles = '.pdf';
  }

  public ngOnInit(): void {
    this.control = this.injector.get(NgControl);
  }

  public ngAfterViewInit(): void {
    const controlCheck = this.control?.control?.validator(
      this.control?.control,
    );
    if (controlCheck?.['required']) {
      this.label = `${this.label}`;
    }
  }

  public onTouched: Function = () => {};

  public onChange: Function = (ev: any) => {};

  protected _value = '';

  public get value() {
    return this._value;
  }

  public get invalid(): boolean {
    return false;
  }

  public get touched(): boolean {
    return true;
  }

  /** value setter */
  @Input() set value(v) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  public onBlur(): void {
    this.onTouched();
  }

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: (data: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public showPassword(): void {
    const input = this.inputPassword?.nativeElement?.attributes as NamedNodeMap;
    if (input.getNamedItem('type')?.value === 'password') {
      this.inputPassword?.nativeElement?.setAttribute('type', 'text');
      this.iconPassword = 'assets/images/icon-eye-off.svg';
      return;
    }

    this.inputPassword?.nativeElement?.setAttribute('type', 'password');
    this.iconPassword = 'assets/images/icon-eye.svg';
  }

  public setClassInput() {
    return {
      [`disable-input`]: this.disable,
    };
  }

  emmitpdf(event) {
    this.changePdf.emit(event);
  }
}
