import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat',
  standalone: true,
})
export class FormatCurrencyPipe implements PipeTransform {
  public transform(
    value: string | number,
    currencySymbol?: string,
    currency?: boolean,
    isIncome?: boolean,
  ) {
    if (!value) {
      if (value === 0) {
        let formattedNumber = Number(0).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });

        if (!currencySymbol && !currency) {
          return formattedNumber.replace(/[$]/i, '');
        }
        if (currency) {
          return `${formattedNumber}`;
        }
        return `${formattedNumber} ${currencySymbol}`;
      }
      return '';
    }
    return formatCurrency(value, currencySymbol, currency, isIncome);
  }
}
const formatCurrency = (
  number: string | number,
  currencySymbol?: string,
  currency?: boolean,
  isIncome?: boolean,
) => {
  try {
    if ((number && !isNaN(Number(number))) || Number(number) === 0) {
      let formattedNumber: string;

      let parsedNumber = number?.toString();

      parsedNumber = truncNumber(parsedNumber, 2);

      formattedNumber = Number(parsedNumber).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      if (isIncome !== undefined && isIncome !== null) {
        formattedNumber = isIncome
          ? `+${formattedNumber}`
          : `-${formattedNumber}`;
      }
      if (!currencySymbol && !currency) {
        return formattedNumber.replace(/[$]/i, '');
      } else if (currency) {
        return `${formattedNumber}`;
      }
      return `${formattedNumber} ${currencySymbol}`;
    } else {
      return '';
    }
  } catch (error) {
    throw new Error(`'ERROR: '${error}`);
  }
};

const truncNumber = (x, args: string | number) => {
  let s = x.toString();
  let l = s.length;
  let decimalLength = s.indexOf('.') + 1;
  args = Number(args);

  if (l - decimalLength <= args) {
    return x.toString();
  }
  let isNeg = x < 0;
  let decimal = x % 1;
  let entera = isNeg ? Math.ceil(x) : Math.floor(x);
  let decimalFormated = Math.floor(Math.abs(decimal) * Math.pow(10, args));
  let finalNum =
    entera + (decimalFormated / Math.pow(10, args)) * (isNeg ? -1 : 1);

  return finalNum.toString();
};
