export const REPORTS_HEADERS = [
  {
    id: 'createdAt',
    name: 'USERS.DETAIL.HEADERS.DATE',
    config: {
      type: 'date',
      format: 'dd/MM/yyyy',
    },
  },
  {
    id: 'customerName',
    name: 'USERS.DETAIL.HEADERS.CLIENT',
  },
  {
    id: 'customerFirstName',
    name: 'USERS.DETAIL.HEADERS.CLIENT_FATHER_NAME',
  },
  {
    id: 'customerLastName',
    name: 'USERS.DETAIL.HEADERS.CLIENT_MOTHER_NAME',
  },
  {
    id: 'clientId',
    name: 'USERS.DETAIL.HEADERS.CLIENT_ID',
  },
  {
    id: 'id',
    name: 'USERS.DETAIL.HEADERS.TRANSACTION_ID',
    config: {
      type: 'transaction_id',
      format: 'dd/MM/yyyy',
    },
  },
  {
    id: 'beneficiaryAccount',
    name: 'USERS.DETAIL.HEADERS.DESTINATION',
  },
  {
    id: 'amount',
    name: 'USERS.DETAIL.HEADERS.AMOUNT',
    config: {
      type: 'currency',
    },
  },
];

export const mapTransactionElement = transaction => ({
  ...transaction,
  customerName: transaction.customer.name,
  customerFirstName: transaction.customer.firstname,
  customerLastName: transaction.customer.lastname,
  clientId: transaction.customer.id,
  beneficiaryAccount: transaction.bank?.beneficiaryAccount,
  amount: transaction.total,
});

export const mapDateToString = (date: Date) =>
  date ? `${date.toISOString().slice(0, 10)}` : null;
