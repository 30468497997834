import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ITEMS_PER_PAGE, NUMBER_ONE } from '@shared/constants';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() page: number;
  @Input() itemsPerPage: number;
  @Input() totalElements: number;
  @Input() visibleRangeLength: number;
  @Input() pages: string[];
  @Input() showUpdateText: boolean;

  @Output() PageChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() ItemsPerPage: EventEmitter<any> = new EventEmitter<any>();

  public totalPages: number = 0;
  public visiblePages: number[] = [];

  public constructor() {
    this.page = 1;
    this.itemsPerPage = 10;
    this.visibleRangeLength = 4;
    this.totalElements = 500;
    this.showUpdateText = false;
    this.pages = ITEMS_PER_PAGE;
  }

  public ngOnInit(): void {
    this.updateTotalPages();
    this.updateVisiblePages();
  }
  public ngOnChanges(): void {
    this.updateTotalPages();
    this.updateVisiblePages();
  }
  private updateTotalPages(): void {
    this.totalPages =
      this.totalElements > this.itemsPerPage
        ? Math.ceil(this.totalElements / this.itemsPerPage)
        : NUMBER_ONE;
  }

  private updateVisiblePages(): void {
    const length = Math.min(this.totalPages, this.visibleRangeLength);

    const startIndex = Math.max(
      Math.min(this.page - Math.ceil(length / 2), this.totalPages - length),
      0,
    );

    this.visiblePages = Array.from(
      new Array(length).keys(),
      item => item + startIndex + 1,
    );
  }

  public selectPage(page: number): void {
    this.page = page;
    this.PageChange.emit({ page, itemsPerPage: this.itemsPerPage });
    this.updateVisiblePages();
  }

  public selectItemsPerPage(items: string): void {
    this.itemsPerPage = Number(items);
    this.ItemsPerPage.emit(this.itemsPerPage);
    this.updateVisiblePages();
  }

  public trackById(item: any) {
    return item;
  }
}
