<div class="wrapper">
  <div *ngIf="!body?.elements?.length" class="users__section__empty">
    <ng-content select="[emptyState]"></ng-content>
  </div>
  <table *ngIf="body?.elements?.length" class="table table-padding">
    <caption></caption>
    <thead>
      <tr>
        <th
          [style]="getStylesTableHead(header.width, header.unit)"
          *ngFor="let header of headers; let ind = index">
          <div *ngIf="header?.config?.type !== 'check'" class="vectors">
            {{ header.name | translate }}
            <div class="items" *ngIf="header?.order && body?.totalElements > 0">
              <img
                #up
                [class.content-hidden]="
                  header?.config?.type === 'arrow-right-detail'
                "
                (click)="filterTable(ind, header, false)"
                src="assets/images/vector-up.svg"
                alt="vector-up" />

              <img
                #down
                [class.content-hidden]="
                  header?.config?.type === 'arrow-right-detail'
                "
                (click)="filterTable(ind, header, true)"
                src="assets/images/vector.svg"
                alt="vector" />
            </div>
          </div>
          <div *ngIf="header?.config?.type === 'check'">
            <label class="container-radio-button">
              <input
                [id]="ind"
                [name]="ind"
                type="checkbox"
                type="checkbox"
                (change)="
                  selectAllField(
                    $event,
                    header.config.propertyConfigAction[0]?.callback,
                    header.config.propertyConfigAction[0]?.checkboxSelectItems
                  )
                " />
              <span class="checkmark-radio-button"></span>
            </label>
            <img
              *ngIf="showCheckboxSelectItems"
              class="arrow-checkbox"
              src="assets/images/arrow-drop-down.svg"
              alt="" />
            <ul
              id="checklist-select"
              *ngIf="showCheckboxSelectItems"
              class="table-checkbox-select">
              <li
                *ngFor="
                  let item of header.config.propertyConfigAction[0]
                    ?.checkboxSelectItems
                "
                [ngClass]="{
                  'table-checkbox-select-item-disable': item.disable
                }"
                (click)="
                  selectValueCheckBoxItems(
                    item.id,
                    header.config.propertyConfigAction[0]
                      ?.callbackCheckBoxSelectItems
                  )
                ">
                <span>{{ item.id | translate }} </span>
              </li>
            </ul>
          </div>
        </th>
      </tr>
    </thead>
    <tbody
      [class.content-height-with-loader]="loading || body.totalElements === 0">
      <ng-container *ngFor="let item of body?.elements; let ind = index">
        <tr>
          <td
            *ngFor="let header of headers; let i = index"
            (click)="onRowClick(item)">
            <div *ngIf="header.config; else text">
              <ng-container #cases [ngSwitch]="header?.config.type">
                <div *ngSwitchCase="'check'">
                  <label class="container-radio-button">
                    <input
                      #check
                      [id]="ind"
                      [name]="ind"
                      type="checkbox"
                      (change)="
                        selectField(
                          item,
                          header.config.propertyConfigAction[0]?.callback,
                          header.config.propertyConfigAction[0]
                            ?.checkboxSelectItems
                        )
                      "
                      type="checkbox" />
                    <span class="checkmark-radio-button"></span>
                  </label>
                </div>
                <div
                  *ngSwitchCase="'input'"
                  class="input-amount-table"
                  [ngStyle]="item.extras?.[header.id]?.style">
                  <input
                    type="number"
                    step="1"
                    [value]="item[header.id]"
                    (input)="
                      changeInput(
                        $event.target,
                        item,
                        header.config.propertyConfigAction[0]
                          ?.callbackChangeInput
                      )
                    " />
                </div>
                <div
                  *ngSwitchCase="'date'"
                  [ngStyle]="item.extras?.[header.id]?.style">
                  {{ item[header.id] | localizeDate | date :
                  header?.config?.format }}
                </div>
                <div
                  *ngSwitchCase="'currency'"
                  [ngStyle]="item.extras?.[header.id]?.style">
                  {{ item[header.id] | currencyFormat : null : true }}
                </div>
                <div
                  *ngSwitchCase="'percent'"
                  [ngStyle]="item.extras?.[header.id]?.style">
                  {{ item[header.id] }}%
                </div>
                <div
                  *ngSwitchCase="'term'"
                  [ngStyle]="item.extras?.[header.id]?.style">
                  {{ item[header.id] ? item[header.id] : 0 }} {{
                  "MERCHANT.CUSTOMER.TERM_COMPLEMENT" | translate }}
                </div>
                <div
                  class="text-icon"
                  *ngSwitchCase="'text-icon'"
                  [ngStyle]="item.extras?.[header.id]?.style">
                  <img
                    [src]="header.config.propertyConfigText.nameIcon(item)"
                    [alt]="item[header.id]" />
                  {{ item[header.id] }}
                </div>
                <div
                  class="status-bullet"
                  *ngSwitchCase="'status-bullet'"
                  [ngStyle]="item.extras?.[header.id]?.style">
                  <div
                    class="group {{
                      header?.config.propertyConfigText.colorBullet(item)
                    }}">
                    <div
                      class="bullet {{
                        header?.config.propertyConfigText.colorBullet(item)
                      }}"></div>
                    {{ header?.config.propertyConfigText.text(item) | translate
                    }}
                  </div>
                  <div
                    class="container-tooltip"
                    *ngIf="header?.config.propertyConfigText.tooltip">
                    <p>
                      {{ header?.config.propertyConfigText.tooltip | translate
                      }}
                    </p>
                  </div>
                </div>
                <div class="icons" *ngSwitchCase="'icons'">
                  <ng-container
                    *ngFor="let icon of header.config.propertyConfigIcons">
                    <div
                      class="icon"
                      [ngClass]="hiddenIcon(icon.showIcon(item))">
                      <img
                        [src]="icon.nameIcon"
                        (click)="icon.callback(item)"
                        [alt]="item[header.id]" />
                      <div *ngIf="icon?.tooltip" class="container-tooltip-icon">
                        <p>{{ icon?.tooltip | translate }}</p>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="icons" *ngSwitchCase="'icons-customer'">
                  <ng-container
                    *ngFor="let icon of header.config.propertyConfigIcons">
                    <div
                      *ngIf="
                        item?.contract ||
                        !icon.nameIcon.includes(
                          'assets/images/configuration-info-card.svg'
                        )
                      "
                      class="icon"
                      [ngClass]="hiddenIcon(icon.showIcon(item))">
                      <img
                        [src]="icon.nameIcon"
                        (click)="icon.callback(item)"
                        [alt]="item[header.id]" />
                      <div *ngIf="icon?.tooltip" class="container-tooltip-icon">
                        <p>{{ icon?.tooltip | translate }}</p>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div
                  [class]="selectClassStatus(item[header.id])?.color"
                  *ngSwitchCase="'status'"
                  [ngStyle]="item.extras?.[header.id]?.style">
                  {{ selectClassStatus(item[header.id])?.text | translate }}
                </div>
                <div
                  [class]="selectClassStatusSat(item[header.id])?.color"
                  *ngSwitchCase="'status_sat'"
                  [ngStyle]="item.extras?.[header.id]?.style">
                  {{ selectClassStatusSat(item[header.id])?.text | translate }}
                </div>
                <div
                  [class]="selectClassStatusContract(item[header.id])?.color"
                  *ngSwitchCase="'status_contract'"
                  [ngStyle]="item.extras?.[header.id]?.style">
                  {{ selectClassStatusContract(item[header.id])?.text |
                  translate }}
                </div>
                <div *ngSwitchCase="'transaction_id'" class="transaction-color">
                  <a (click)="onRowClick(item)">{{ item[header.id] }}</a>
                </div>
                <p *ngSwitchDefault>-</p>
              </ng-container>
            </div>
            <ng-template #text>
              <div
                [ngStyle]="item.extras?.[header.id]?.style"
                [ngClass]="{
                  'transaction-color': header.id === 'transactionId'
                }"
                class="content-text">
                <img
                  [src]="item.extras?.[header.id]?.icon"
                  [alt]="item[header.id]"
                  *ngIf="item.extras?.[header.id]?.icon" />
                {{ item[header.id] }}
              </div>
            </ng-template>
          </td>
        </tr>
        <tr [ngClass]="getIndex(ind)">
          <td [colSpan]="getWidthDetails()">
            <app-detail-table [ngSwitch]="typeDetail"> </app-detail-table>
          </td>
        </tr>
      </ng-container>
      <div class="content-loader" *ngIf="loading">
        <img width="60" height="60" src="assets/images/loading.gif" alt="" />
      </div>
    </tbody>
  </table>
</div>

<footer *ngIf="showPaginationSection">
  <app-pagination
    class="pagination"
    style="width: 100%"
    *ngIf="body && !(body?.totalElements === 0) && showPaginationSection"
    [itemsPerPage]="body?.limit"
    [page]="body?.page"
    [totalElements]="body?.totalElements"
    [pages]="pages"
    (PageChange)="onEventPagination($event)"
    (ItemsPerPage)="onItemsPerPage($event)"></app-pagination>
</footer>
