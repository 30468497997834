import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDataHeader } from '../table.types';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-table-light',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input() headers: IDataHeader[];
  @Input() body;
  @Input() typeDetail: string;
  @Input() selected = [];
  @Input() loading: boolean = false;

  @Output() rowClick = new EventEmitter();

  public getStylesTableHead(width: string | number, unit?: string) {
    const result = width ? `${width}${unit || '%'}` : 'initial';
    return {
      width: result,
      'min-width': result,
      'background-color': '#F4F6F9',
    };
  }

  public onRowClick(item: any) {
    this.rowClick.emit(item);
  }
}
