import { Helpers } from '@shared/enums/helpers.enum';
import { ACTIVE, INACTIVE, PENDING } from './user-status.constant';

export const TRANSACTION_TABLE_HEADERS = [
  {
    id: 'createdAt',
    name: 'USERS.DETAIL.HEADERS.DATE',
    config: {
      type: 'date',
      format: 'dd/MM/yyyy',
    },
  },
  {
    id: 'clientName',
    name: 'USERS.DETAIL.HEADERS.CLIENT',
  },
  {
    id: 'clientFatherName',
    name: 'USERS.DETAIL.HEADERS.CLIENT_FATHER_NAME',
  },
  {
    id: 'clientMotherName',
    name: 'USERS.DETAIL.HEADERS.CLIENT_MOTHER_NAME',
  },
  {
    id: 'clientId',
    name: 'USERS.DETAIL.HEADERS.CLIENT_ID',
  },
  {
    id: 'transactionId',
    name: 'USERS.DETAIL.HEADERS.TRANSACTION_ID',
    config: {
      type: 'transaction_id',
      format: 'dd/MM/yyyy',
    },
  },
  {
    id: 'beneficiaryAccount',
    name: 'USERS.DETAIL.HEADERS.DESTINATION',
  },
  {
    id: 'amount',
    name: 'USERS.DETAIL.HEADERS.AMOUNT',
    config: {
      type: 'currency',
    },
  },
];

export const LEVELS_TABLE_HEADERS = [
  {
    id: 'function',
    name: 'LEVELS.HEADERS.FUNCTION',
  },
  {
    id: 'level_1',
    name: 'LEVELS.HEADERS.LEVEL_1',
  },
  {
    id: 'level_2',
    name: 'LEVELS.HEADERS.LEVEL_2',
  },
  {
    id: 'level_3',
    name: 'LEVELS.HEADERS.LEVEL_3',
  },
];

export const COMISSIONS_TABLE_HEADERS = [
  {
    id: 'concept',
    name: 'COMISSIONS.HEADERS.CONCEPT',
  },
  {
    id: 'amount',
    name: 'COMISSIONS.HEADERS.AMOUNT',
  },
  {
    id: 'periodicity',
    name: 'COMISSIONS.HEADERS.PERIODICITY',
  },
];

export const USERS_TABLE_HEADERS = [
  {
    id: 'name',
    name: 'USERS.TABLE.HEADERS.NAME',
  },
  {
    id: 'firstSurname',
    name: 'USERS.TABLE.HEADERS.FATHER_NAME',
  },
  {
    id: 'lastSurname',
    name: 'USERS.TABLE.HEADERS.MOTHER_NAME',
  },
  {
    id: 'id',
    name: 'USERS.TABLE.HEADERS.CLIENT_ID',
  },
  {
    id: 'curp',
    name: 'USERS.TABLE.HEADERS.CURP',
  },
  {
    id: 'email',
    name: 'USERS.TABLE.HEADERS.EMAIL',
  },
  {
    id: 'lastAccess',
    name: 'USERS.TABLE.HEADERS.LAST_CONNECTION',
    config: {
      type: 'date',
      format: 'dd/MM/yyyy hh:mm',
    },
  },
  {
    id: 'status',
    width: 5,
    unit: 'vw',
    name: 'USERS.TABLE.HEADERS.STATUS',
    config: {
      type: 'status-bullet',
      propertyConfigText: {
        colorBullet: ev => validateStatus(ev)?.color,
        text: ev => validateStatus(ev)?.text,
      },
    },
  },
];

function validateStatus(user): any {
  if (ACTIVE.includes(user.status)) {
    return {
      color: Helpers.GREEN,
      text: 'USERS.STATUS.ACTIVE',
    };
  }
  if (PENDING.includes(user.status)) {
    return {
      color: Helpers.YELLOW,
      text: 'USERS.STATUS.PENDING',
    };
  }
  if (INACTIVE.includes(user.status)) {
    return {
      color: Helpers.GRAY,
      text: 'USERS.STATUS.INACTIVE',
    };
  }
  return {};
}
