import { TransactionType } from '@shared/models/transactions.model';

type TransactionTypesMapping = { [n: string]: TransactionType[] };

export const SELECT_MAPPING: TransactionTypesMapping = {
  'Desde tarjeta': [
    'PURCHASE',
    'WITHDRAWAL',
    'EXTRACASH',
    'REFUND',
    'PAYMENT',
    'REVERSAL_PURCHASE',
    'REVERSAL_WITHDRAWAL',
    'REVERSAL_EXTRACASH',
    'REVERSAL_REFUND',
    'REVERSAL_PAYMENT',
  ],
  'Desde CLABE': [
    'SERVICE_PAYMENT',
    'SPEI_IN',
    'SPEI_OUT',
    'SPEI_OUT_INTERNAL',
    'ROLLBACK',
  ],
  'Billetera a billetera': ['SPEI_OUT_INTERNAL', 'SPEI_IN_INTERNAL'],
  'Billetera a CLABE': ['ROLLBACK', 'SPEI_IN', 'SPEI_OUT'],
  'Billetera a tarjeta': ['SPEI_OUT'],
  'Pago de servicios': ['SERVICE_PAYMENT'],
};
