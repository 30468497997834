<div class="wrapper">
  <table class="table">
    <caption></caption>
    <thead>
      <tr>
        <th
          [style]="getStylesTableHead(header.width, header.unit)"
          *ngFor="let header of headers; let ind = index">
          <div *ngIf="header?.config?.type !== 'check'" class="vectors">
            {{ header.name | translate }}
          </div>
        </th>
      </tr>
    </thead>
    <tbody
      [class.content-height-with-loader]="loading || body?.totalElements === 0">
      <ng-container *ngFor="let item of body?.elements; let ind = index">
        <tr>
          <td
            *ngFor="let header of headers; let i = index"
            (click)="onRowClick(item)">
            <div
              [ngStyle]="item.extras?.[header.id]?.style"
              [ngClass]="{
                'transaction-color': header.id === 'transactionId'
              }"
              class="content-text">
              <img
                [src]="item.extras?.[header.id]?.icon"
                [alt]="item[header.id]"
                *ngIf="item.extras?.[header.id]?.icon" />
              {{ item[header.id] }}
            </div>
          </td>
        </tr>
      </ng-container>
      <div class="content-loader" *ngIf="loading">
        <img width="60" height="60" src="assets/images/loading.gif" alt="" />
      </div>
    </tbody>
  </table>
</div>
