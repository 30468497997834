import { Observable } from 'rxjs';

export interface IStateConfig {
  i18nLang?: string;
}

export interface IStateMessages {
  secret_key_not_found: string;
  store_exists: string;
  store_not_found: string;
}

export interface IState {
  clearAll(): void;
  // tslint:disable-next-line: no-any
  get(key: string): any;
  remove(key: string): void;
  // tslint:disable-next-line: no-any
  set(key: string, value: any): void;
}

export interface IModel<T> {
  state: Observable<T>;
  getValue(): T;
  set(data: T): void;
}
