<label *ngIf="!!label">{{ label }}</label>

<div class="search" tabindex="0">
  <input
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [type]="type"
    (keydown.enter)="onSearch()" />
  <div class="item">
    <img class="icon-search" src="assets/images/search.svg" alt="search" />
  </div>
</div>
