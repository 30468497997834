import { StateService } from '@core/services/state';
import { StateName } from './state-name.enum';

export enum ModalDefaults {
  MODAL_CHANGE_UDIS = 'app-change-udis-modal',
  MODAL_UPDATE_USER = 'app-update-user-modal',
  MODAL_TRANSACTION_DETAILS = 'app-transaction-details-modal',
  MODAL_CARD_DETAILS = 'app-card-details-modal',
  MODAL_CUSTOMER_DOCUMENTS = 'app-customer-documents-modal',
}

export const ModalTopicCall = (
  state: StateService,
  message,
  name: ModalDefaults,
  payload,
) => {
  state.set(StateName.modal_service, {
    modal: name,
    message,
    payload,
  });
};
