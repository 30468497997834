<section class="modal">
  <div class="modal-content">
    <form class="modal-body" [formGroup]="form">
      <div class="close-modal">
        <h1>{{ "DETAIL_TRANSACTION.TITLE" | translate }}</h1>
        <div class="buttons">
          <app-button-select
            [txtButton]="'DETAIL_TRANSACTION.BUTTONS.DOWNLOAD_PDF'"
            [ico]="'assets/images/download-csv.svg'"
            [loading]="loading"
            (buttonClick)="downloadPDF()"></app-button-select>
          <img
            (click)="close(false)"
            src="assets/images/close.svg"
            alt="close-icon" />
        </div>
      </div>
      <div class="body-content">
        <div class="modal-section">
          <h2>
            {{ "TRANSACTION_TYPES." + data.transaction.type | translate }}
          </h2>
          <div class="modal-section-info">
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_TRANSACTION.COLUMS.TYPE" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ "TRANSACTION_TYPES." + data.transaction.type | translate }}
              </div>
            </div>
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_TRANSACTION.COLUMS.ORIGIN_ACCOUNT" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ data.transaction.bank?.payerAccount }}
              </div>
            </div>
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_TRANSACTION.COLUMS.DESTINY_ACCOUNT" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ data.transaction.bank?.beneficiaryAccount }}
              </div>
            </div>
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_TRANSACTION.COLUMS.CONCEPT" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ data.transaction.bank?.concept }}
              </div>
            </div>
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_TRANSACTION.COLUMS.TRANSACTION_ID" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ data.transaction.id }}
              </div>
            </div>
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_TRANSACTION.COLUMS.AMMOUNT" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ data.transaction.amount | currencyFormat : null : true}}
              </div>
            </div>
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_TRANSACTION.COLUMS.FEE" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ data.transaction.fee | currencyFormat : null : true }}
              </div>
            </div>
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_TRANSACTION.COLUMS.VAT" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ data.transaction.vatAmount | currencyFormat : null : true }}
              </div>
            </div>
            <div class="modal-section-info-row">
              <div class="modal-section-info-row-title">
                {{ "DETAIL_TRANSACTION.COLUMS.TOTAL" | translate }}
              </div>
              <div class="modal-section-info-row-subtitle">
                {{ total | currencyFormat : null : true }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-section">
          <h2>Datos del cliente</h2>
          <div class="modal-section-info-row">
            <div class="modal-section-info-row-title">
              {{ "DETAIL_TRANSACTION.COLUMS.CLIENT_NAME" | translate }}
            </div>
            <div class="modal-section-info-row-subtitle">
              {{ data.transaction.customer.name }} {{
              data.transaction.customer.firstname }} {{
              data.transaction.customer.lastname }}
            </div>
          </div>
          <div class="modal-section-info-row">
            <div class="modal-section-info-row-title">
              {{ "DETAIL_TRANSACTION.COLUMS.CLIENT_ID" | translate }}
            </div>
            <div class="modal-section-info-row-subtitle">
              {{ data.transaction.customer.id }}
            </div>
          </div>
        </div>
        <div class="modal-section">
          <div class="modal-section-info-row">
            <div class="modal-section-info-row-title">
              {{ "DETAIL_TRANSACTION.COLUMS.STATUS" | translate }}
            </div>
            <div
              class="modal-section-info-row-subtitle"
              [class]="getStatus(data.transaction.bank?.status).color">
              {{ getStatus(data.transaction.bank?.status).label | translate }}
            </div>
          </div>
          <div class="modal-section-info-row">
            <div class="modal-section-info-row-title">
              {{ "DETAIL_TRANSACTION.COLUMS.DATE" | translate }}
            </div>
            <div class="modal-section-info-row-subtitle">
              {{ (data.transaction.createdAt | localizeDate) | date : "longDate"
              }}
            </div>
          </div>
          <div class="modal-section-info-row">
            <div class="modal-section-info-row-title">
              {{ "DETAIL_TRANSACTION.COLUMS.HOUR" | translate }}
            </div>
            <div class="modal-section-info-row-subtitle">
              {{ (data.transaction.createdAt | localizeDate) | date :
              "shortTime" }} {{ "REPORTS.SUFIX_TIME" | translate }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
