import { PagedResponse, ResponseApi } from '@shared/models';
import { NUMBER_ONE, NUMBER_ZERO } from './numbers.constants';

export function EmptyPagedResponse<T>(): ResponseApi<PagedResponse<T>> {
  return {
    data: {
      elements: [],
      totalElements: NUMBER_ZERO,
      limit: NUMBER_ONE,
      page: NUMBER_ONE,
    },
    message: 'Error',
  };
}
