import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@core/guards';
import { PageNotFoundComponent } from '@modules/page-not-found/page-not-found.component';
import { RoutingPathsEnum } from '@shared/enums/routing-paths.enum';

const routes: Routes = [
  {
    path: RoutingPathsEnum.INITIAL_RELATIVE,
    loadChildren: () =>
      import('./modules/panel/panel.module').then(m => m.PanelModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: RoutingPathsEnum.AUTH,
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: RoutingPathsEnum.WILDCARD,
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
