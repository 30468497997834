export const ngxMaskPatterns = {
  A: {
    pattern: /[A-zÀ-ÿ\s']/i,
    optional: true,
  },
  B: {
    pattern: /[A-zÀ-ÿ0-9\s'º.-]/i,
    optional: true,
  },
  0: {
    pattern: /\d/i,
    optional: true,
  },
};
