<fieldset [ngClass]="inputStyle">
  <legend *ngIf="!!label">{{ label }}</legend>
  <div id="content-select-{{ idSelect }}" class="select" tabindex="0">
    <div (click)="openList()" class="select__header">
      <div class="select--icons">
        <div class="text" *ngIf="itemSelected">{{ itemSelected }}</div>
        <div class="text" *ngIf="!itemSelected">{{ placeholder }}</div>
      </div>
      <div class="item">
        <img
          class="img"
          [ngClass]="getClassArrow()"
          src="assets/images/arrow-drop-down.svg"
          alt="open" />
      </div>
    </div>
    <ul
      id="list-select-{{ idSelect }}"
      class="option-list"
      [ngClass]="getClass()">
      <div *ngIf="searchEnabled" class="search">
        <input
          type="text"
          [value]="inputValue"
          id="mySearchOption-{{ idSelect }}" />
        <img
          class="search__icon-search"
          src="assets/images/icon-search.svg"
          alt="search" />
      </div>
      <li
        *ngFor="let opt of optionsFilter; trackBy: identify"
        (click)="selectValue(opt)">
        <span>{{ opt.label }} </span>
      </li>
    </ul>
  </div>
</fieldset>
