import {
  APP_INITIALIZER,
  Injector,
  LOCALE_ID,
  NgModule,
  Provider,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { JwtInterceptor, UnauthorizedInterceptor } from '@core/interceptors';
import { PageNotFoundComponent } from '@modules/page-not-found/page-not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalsService } from '@core/services/modal-bus/modals.service';
import { ModalsModule } from '@core/modals/modals.module';
import { ToastrModule } from 'ngx-toastr';
import { ToastComponent } from '@shared/components/index';
import { provideAnimations } from '@angular/platform-browser/animations';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AuthService, StateService, StorageService } from '@core/services';
import { StateName, StorageKey } from '@shared/enums';
import { firstValueFrom } from 'rxjs';
import { ErrorInterceptor } from '@core/interceptors/error.interceptor';

registerLocaleData(localeEs, 'es');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

function modalsServiceInit(modalsService: ModalsService, injector: Injector) {
  return () => modalsService.registerModals(injector);
}

function initializeApp(
  storageService: StorageService,
  authService: AuthService,
  state: StateService,
) {
  return async (): Promise<boolean> => {
    if (storageService.get(StorageKey.ACCESS_TOKEN)) {
      try {
        const me = await firstValueFrom(authService.me());

        state.set(StateName.user_authenticated, me);
        state.set(StateName.user_roles, me.roles);
        state.set(StateName.user_permissions, me.permissions);
        return true;
      } catch (error) {
        return true;
      }
    }

    return true;
  };
}
// Do some asynchronous stuff

const onInitModal: Provider = {
  provide: APP_INITIALIZER,
  useFactory: modalsServiceInit,
  multi: true,
  deps: [ModalsService, Injector],
};

const onAppLoader: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeApp,
  multi: true,
  deps: [StorageService, AuthService, StateService],
};

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, ToastComponent],
  imports: [
    ModalsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'es',
    }),
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
      toastClass: 'toast-success-app',
    }),
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    onInitModal,
    onAppLoader,
    provideAnimations(),
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
