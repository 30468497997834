import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedResponse, ResponseApi } from 'src/app/shared/models';
import { ApiService } from './api.service';
import { Metrics, Transaction } from '@shared/models/transactions.model';
import {
  ITransactionMetricsParams,
  ITransactionParams,
} from '@shared/models/transaction-params.model';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  private url = '/v1/transaction';
  constructor(private apiService: ApiService) {}

  public getById(id: string): Observable<ResponseApi<Transaction>> {
    return this.apiService.get(`${this.url}/${id}`);
  }

  public getAll(
    params?: ITransactionParams,
  ): Observable<ResponseApi<PagedResponse<Transaction>>> {
    return this.apiService.get(this.url, { params });
  }

  public downloadCSVFile(params?: ITransactionParams): Observable<any> {
    return this.apiService.get(`${this.url}/download`, {
      params,
      observe: 'response',
      responseType: 'blob',
    });
  }

  downloadPDFFile(transactionId): Observable<any> {
    return this.apiService.get(`/v1/transaction/${transactionId}/download`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  public getMetrics(
    params?: ITransactionMetricsParams,
  ): Observable<ResponseApi<Metrics>> {
    return this.apiService.get('/v1/customer/panel/metrics', { params });
  }
}
