import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UserDocumentType } from '@core/constants/user-document-type.constant';
import { UserService } from '@core/services';
import { UserDocument } from '@shared/models/user/user-document.model';

@Component({
  selector: 'app-costumer-documents',
  templateUrl: './costumer-documents.component.html',
  styleUrls: ['./costumer-documents.component.scss'],
})
export class CostumerDocumentsComponent implements OnInit {
  @Output() public readonly closed: EventEmitter<boolean> = new EventEmitter();

  @Input() public data: {
    customerId: string;
  };

  public isLoading: boolean = false;
  public imageFiles: Array<UserDocument> = [];
  public pdfFiles: Array<UserDocument> = [];

  constructor(private readonly userService: UserService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.getDocumentsByCustomerId(this.data.customerId).subscribe({
      next: res => {
        this.imageFiles = res.data.filter(({ type }) =>
          [
            UserDocumentType.INE_BACK,
            UserDocumentType.SIGN_CONTRACT,
            UserDocumentType.INE_FRONT,
            UserDocumentType.SELFIE,
          ].includes(type),
        );
        this.pdfFiles = res.data.filter(({ type }) =>
          [
            UserDocumentType.CUSTOMER_CONTRACT_FOR_PLD,
            UserDocumentType.CUSTOMER_CONTRACT,
          ].includes(type),
        );
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  public close(callService: boolean = false): void {
    this.closed.emit(callService);
    this.closed.complete();
  }
}
