<div class="wrapper">
  <div id="content-select-fieldset-{{ idSelect }}" class="container_input">
    <img class="input-img" *ngIf="iconSrc" [src]="iconSrc" alt="image" />
    <div class="container_input-item">
      <fieldset
        [ngClass]="setClassInput()"
        (blur)="onBlur()"
        (click)="openList()"
        [class.error_input]="classErrorInput"
        [class.disable]="disable">
        <legend [class.error_label]="classErrorInput" *ngIf="!!label">
          {{ label }}
        </legend>
        <input
          id="input-select-fieldset"
          (blur)="onBlur()"
          [(ngModel)]="value"
          [type]="type"
          [disabled]="disable"
          [placeholder]="placeholder"
          [ngClass]="setClassInput()"
          readonly />
        <img
          class="img"
          [ngClass]="getClassArrow()"
          src="assets/images/arrow.svg"
          alt="open" />
      </fieldset>
      <ul id="list-select-fieldset-{{ idSelect }}" [ngClass]="getClass()">
        <div *ngIf="searchEnabled" class="search">
          <input
            class="input-select-fieldset-search"
            type="text"
            [value]="inputValue"
            id="mySearchOption-fieldset-{{ idSelect }}" />
          <img
            class="search__icon-search"
            src="assets/images/icon-search.svg"
            alt="search" />
        </div>
        <li *ngFor="let opt of optionsFilter" (click)="selectValue(opt)">
          <span>{{ opt }} </span>
        </li>
      </ul>
    </div>
  </div>

  <span id="span-error" *ngIf="inputRequired"> {{ errorRequired }} </span>
  <span id="span-error" *ngIf="validateMaxLengthAndMinLength">
    {{ errorRequired }}
  </span>
  <span id="span-error" *ngIf="validatePattern"> {{ errorPattern }} </span>
  <span id="span-error" *ngIf="genericError"> {{ genericErrorText }} </span>
</div>
