import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Output() clicked: EventEmitter<any> = new EventEmitter();

  handleClick($event: MouseEvent) {
    $event.preventDefault();
    this.clicked.emit(this.checked);
  }
}
