import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RoutingPathsEnum, StorageKey } from 'src/app/shared/enums';
import { ApiService } from '.';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import {
  AuthenticatedUser,
  LoginForm,
  ResponseApi,
  UserLogin,
} from '@shared/models';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { UserRole } from '@core/constants/user-role.constant';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authenticatedUser: BehaviorSubject<AuthenticatedUser | null> =
    new BehaviorSubject<AuthenticatedUser | null>(null);

  private readonly URL_BY_ROLE: {
    [key in UserRole]: RoutingPathsEnum;
  } = {
    ADMIN_ROLE: RoutingPathsEnum.REPORTS,
    CUSTOMER_SUPPORT_ROLE: RoutingPathsEnum.USERS,
  };

  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
    private router: Router,
    private idle: Idle,
  ) {}

  public login(userLogin: LoginForm): Observable<ResponseApi<UserLogin>> {
    return this.apiService
      .post(`/v1/auth/login/panel`, userLogin)
      .pipe(
        tap((response: ResponseApi<UserLogin>) =>
          this.storageService.save(
            StorageKey.ACCESS_TOKEN,
            response.data.accessToken,
          ),
        ),
      );
  }

  public logout(): Observable<ResponseApi<string>> {
    return this.apiService.post(`/v1/auth/logout`, {}).pipe(
      tap(() => {
        this.storageService.remove(StorageKey.ACCESS_TOKEN);
        this.router.navigateByUrl('/auth/login');
      }),
    );
  }

  public me(): Observable<AuthenticatedUser> {
    return this.apiService.get(`/v1/auth/me`).pipe(
      map((response: ResponseApi<AuthenticatedUser>) => response.data),
      tap((response: AuthenticatedUser) => {
        this.authenticatedUser.next(response);
        this.expiredSession();
      }),
    );
  }

  public getAuthenticatedUser(): Observable<AuthenticatedUser | null> {
    return this.authenticatedUser.asObservable();
  }

  public get dataUser(): AuthenticatedUser {
    const user = this.authenticatedUser.value;
    return user;
  }

  public expiredSession(): void {
    this.idle.setIdle(1740);
    this.idle.setTimeout(60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onTimeout.subscribe(() => {
      this.logout().subscribe();
      this.stop();
    });

    this.start();
  }

  public goToHome(userRole: UserRole): void {
    this.router.navigate([this.URL_BY_ROLE[userRole]]);
  }

  private stop(): void {
    this.idle.stop();
  }

  private start(): void {
    this.idle.watch();
  }
}
