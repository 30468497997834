import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true,
    },
  ],
})
export class SearchComponent implements ControlValueAccessor {
  @Input() public type: string;
  @Input() public label: string;
  @Input() public placeholder;
  @Output() public eventSearch: EventEmitter<string> = new EventEmitter();

  constructor() {
    this.type = 'text';
    this.placeholder = '';
  }

  public onTouched = () => {};

  public onChange = (ev: any) => {};

  protected _value = '';
  protected _disable: boolean = false;

  get value() {
    return this._value;
  }

  /** value setter */
  @Input() set value(v) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: (data: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public onSearch(): void {
    this.eventSearch.emit(this.value);
  }
}
