import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { SelectOption } from '@shared/interfaces';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent
  implements OnInit, ControlValueAccessor, AfterViewInit, OnChanges
{
  @Input() inputStyle: 'filter' | 'form-select' = 'filter';
  @Input() options: SelectOption[] = [];
  @Input() label: string;
  @Input() text: string;
  @Input() idSelect: string;
  @Input() searchEnabled: boolean;
  @Input() iconSrc: string;
  @Input() placeholder: string;
  @Output() public readonly valueSelected: EventEmitter<string>;

  public showListItems: boolean;
  public optionsFilter: SelectOption[];
  public inputValue: string = '';
  @Input() public itemSelected: string;

  constructor() {
    this.options = [];
    this.showListItems = false;
    this.label = '';
    this.itemSelected = '';
    this.iconSrc = '';
    this.valueSelected = new EventEmitter();
  }

  public onTouched = () => {};

  public onChange = (ev: any) => {};

  protected _value = '';
  protected _disable: boolean = false;

  get value() {
    return this._value;
  }

  public ngAfterViewInit(): void {
    const mySearchOption = document.getElementById(
      `mySearchOption-${this.idSelect}`,
    );

    if (mySearchOption) {
      const inputHandler = event => this.searchOptionFilter(event);
      mySearchOption.addEventListener('input', inputHandler);
      mySearchOption.addEventListener('propertychange', inputHandler);
    }
  }
  public ngOnChanges(): void {
    this.optionsFilter = JSON.parse(JSON.stringify(this.options));
  }

  public ngOnInit(): void {
    this.optionsFilter = [...this.options];
    this.itemSelected = this.value;
    const box = document.getElementById(`content-select-${this.idSelect}`);
    box?.setAttribute('show_option', false.toString());
    window.addEventListener('click', e => {
      const box = document.getElementById(`content-select-${this.idSelect}`);
      const show_option = box?.getAttribute('show_option');
      const list = document.getElementById(`list-select-${this.idSelect}`);
      if (list) {
        if (
          document
            .getElementById(`content-select-${this.idSelect}`)
            ?.contains(e.target as any)
        ) {
          if (show_option === 'true') {
            list.style.visibility = 'visible';
          } else {
            list.style.visibility = 'hidden';
          }
        } else {
          if (show_option === 'true') {
            list.style.visibility = 'hidden';
            box?.setAttribute('show_option', false.toString());
          } else {
            list.style.visibility = 'visible';
          }
        }
      }
    });
  }

  /** value setter */
  @Input() set value(v) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  public writeValue(value: string): void {
    this.value = value;
    this.itemSelected = this.value;
  }

  public registerOnChange(fn: (data: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public openList() {
    const box = document.getElementById(`content-select-${this.idSelect}`);

    if (box?.getAttribute('show_option') === 'true') {
      box?.setAttribute('show_option', false.toString());
    } else {
      box?.setAttribute('show_option', true.toString());
    }
  }

  public getClass(): Record<string, boolean> {
    const box = document.getElementById(`content-select-${this.idSelect}`);
    box?.getAttribute('show_option');
    if (box?.getAttribute('show_option') === 'true') {
      return {
        [`open`]: true,
      };
    } else {
      return {
        [`close`]: true,
      };
    }
  }

  public getClassArrow(): Record<string, boolean> {
    const box = document.getElementById(`content-select-${this.idSelect}`);
    box?.getAttribute('show_option');
    if (box?.getAttribute('show_option') === 'true') {
      return {
        [`inverted`]: true,
      };
    } else {
      return {
        [``]: true,
      };
    }
  }

  public selectValue(opt: { value: string; label: string }): void {
    const box = document.getElementById(`content-select-${this.idSelect}`);

    if (!opt || this.itemSelected === opt.label) {
      return;
    }
    this.inputValue = this.value;

    this.value = opt.value;
    this.itemSelected = opt.label;
    this.valueSelected.emit(this.value);
    box?.setAttribute('show_option', false.toString());
    this.inputValue = '';
    if (this.searchEnabled) {
      this.optionsFilter = JSON.parse(JSON.stringify(this.options));
    }
  }

  public searchOptionFilter(event): void {
    this.inputValue = event.target.value;
    const searchOptions = this.options?.filter(({ value }) =>
      value
        .toLocaleLowerCase()
        .includes(event.target.value.toLocaleLowerCase()),
    );

    this.optionsFilter = [...searchOptions];
  }

  public identify(index, item) {
    return item.value;
  }
}
