<section class="modal">
  <div class="modal-content">
    <div class="modal-body">
      <div class="close-modal">
        <img
          (click)="close(false)"
          src="assets/images/close.svg"
          alt="close-icon" />
      </div>
      <div class="body-content">
        <div class="modal-body__title">
          <p>{{ title | translate }}</p>
        </div>
        <div class="modal-body__subtitle">
          <p>{{ data?.fullName }}</p>
        </div>
        <div class="modal-body__buttons">
          <ng-container>
            <app-button
              class="button"
              [txtButton]="'USERS.BUTTONS.ACCEPT' | translate"
              idSelect="button-udi-accept"
              (clickButton)="close(true)"
              [loading]="loading"></app-button>
            <app-button
              class="button"
              [txtButton]="'USERS.BUTTONS.CANCEL' | translate"
              color="unfilled"
              idSelect="button-udi-cancel"
              (clickButton)="close(false)"></app-button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
