<div style="display: flex; justify-content: space-between">
  <div style="margin-right: 24px">
    <div
      *ngIf="true"
      [class]="options.titleClass"
      class="toast-title"
      [attr.aria-label]="title"
      style="line-height: 26px">
      {{ title }}
    </div>
    <div
      *ngIf="message && options.enableHtml"
      role="alert"
      [class]="options.messageClass"
      [innerHTML]="message"></div>
    <div
      *ngIf="message && !options.enableHtml"
      role="alert"
      [class]="options.messageClass"
      [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>
  <div class="">
    <a
      *ngIf="!options.closeButton"
      class="btn btn-pink btn-sm"
      (click)="action($event)">
      {{ undoString }}
    </a>
    <a *ngIf="options.closeButton" (click)="remove()" class="">
      <img src="assets/images/close-white.svg" alt="close" />
    </a>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
