import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  BreadcrumbComponent,
  ButtonComponent,
  SelectFieldsetComponent,
  SearchComponent,
  InputComponent,
  HeaderComponent,
  AsideComponent,
  CheckboxComponent,
  TableComponent,
  PaginationComponent,
  LineChartComponent,
  PieChartComponent,
  PaginatedTableComponent,
  SelectComponent,
  ButtonSelectComponent,
  HamburgerMenuComponent,
  StatisticsComponent,
  ToggleComponent,
} from './components';

import {
  FormatCurrencyPipe,
  LocalizeDatePipe,
  ThousandSuffixesPipe,
  SafePipe,
} from './pipes';

import { HasPermissionDirective, HasRoleDirective } from './directives';

import { SelectLineComponent } from './components/select-line/select-line.component';
import { DatePickerComponent } from './components/calendar/calendar.component';
import { InputFieldsetComponent } from './components/input-fieldset/input-fieldset.component';

@NgModule({
  declarations: [
    ButtonComponent,
    BreadcrumbComponent,
    HeaderComponent,
    AsideComponent,
    TableComponent,
    PaginatedTableComponent,
    ButtonSelectComponent,
    PaginationComponent,
    PieChartComponent,
    LineChartComponent,
    HamburgerMenuComponent,
    StatisticsComponent,
    ToggleComponent,
    HasPermissionDirective,
    HasRoleDirective,
  ],
  imports: [
    CommonModule,
    SearchComponent,
    TranslateModule,
    InputComponent,
    CheckboxComponent,
    InputFieldsetComponent,
    DatePickerComponent,
    RouterModule,
    SelectFieldsetComponent,
    FormsModule,
    ReactiveFormsModule,
    FormatCurrencyPipe,
    ThousandSuffixesPipe,
    LocalizeDatePipe,
    SafePipe,
    SelectLineComponent,
    SelectComponent,
  ],
  exports: [
    ButtonComponent,
    SearchComponent,
    InputComponent,
    BreadcrumbComponent,
    HeaderComponent,
    AsideComponent,
    InputFieldsetComponent,
    CheckboxComponent,
    TableComponent,
    PaginatedTableComponent,
    DatePickerComponent,
    TranslateModule,
    SelectFieldsetComponent,
    SelectComponent,
    ButtonSelectComponent,
    HamburgerMenuComponent,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormatCurrencyPipe,
    ThousandSuffixesPipe,
    LocalizeDatePipe,
    SafePipe,
    StatisticsComponent,
    LineChartComponent,
    ToggleComponent,
    HasPermissionDirective,
    HasRoleDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
