import { Transaction, UserData } from '@shared/models';

export const USER_STATUS = {
  active: 'Activo',
  inactive: 'Inactivo',
};
export const QUERY_PARAM_NAME = 'userId';

export const INITIAL_SLICE = 0;
export const END_SLICE = 10;

export const mapTransactionElementUser = (transaction: Transaction) => ({
  ...transaction,
  customerName: transaction.customer.name,
  customerFirstName: transaction.customer.firstname,
  customerLastName: transaction.customer.lastname,
  clientId: transaction.customer.id,
  beneficiaryAccount: transaction.bank?.beneficiaryAccount,
});

export const mapUserTransactionElement =
  (user: UserData) => (transaction: Transaction) => ({
    ...transaction,
    customerName: user.name,
    customerFirstName: user.firstSurname,
    customerLastName: user.lastSurname,
    clientId: user.id,
    beneficiaryAccount: transaction.bank?.beneficiaryAccount,
    amount: transaction.total,
  });
