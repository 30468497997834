import { Component, EventEmitter, Input, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '@core/services';
import { MenuItem } from '@shared/models';
import { Router } from '@angular/router';
import { MenuPathsEnum } from '@shared/enums/routing-paths.enum';

@Component({
  selector: 'app-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss'],
})
export class HamburgerMenuComponent {
  @Input()
  public items: MenuItem[];
  @Output()
  public readonly setConfigSubmenu: EventEmitter<boolean> = new EventEmitter();
  @Input()
  public showConfiguration: boolean;
  public showOption: boolean = false;
  public closeAside: boolean = false;

  public constructor(
    private authService: AuthService,
    public router: Router,
  ) {}

  public openList(item: MenuItem) {
    if (!item?.childrens) {
      this.showOption = false;
      return;
    }
    if (this.showOption) {
      this.showOption = false;
    } else {
      this.showOption = true;
    }
  }

  public getClassArrow(): Record<string, boolean> {
    if (this.showOption) {
      return {
        [`arrow-inverted`]: true,
      };
    } else {
      return {
        [`arrow`]: true,
      };
    }
  }

  public getImgArrow(): string {
    if (this.showOption) {
      return `assets/images/arrow-active-inverted.svg`;
    } else {
      return `assets/images/arrow-active.svg`;
    }
  }

  public getImgArrowDefault(): string {
    if (this.showOption) {
      return `assets/images/arrow-inverted.svg`;
    } else {
      return `assets/images/arrow.svg`;
    }
  }

  public getClass(): Record<string, boolean> {
    if (this.showOption) {
      return {
        [`open-arrow`]: true,
      };
    } else {
      return {
        [`close-arrow`]: true,
      };
    }
  }

  public async logout(): Promise<void> {
    await firstValueFrom(this.authService.logout());
  }

  public navigateReports(): void {
    this.router.navigateByUrl(MenuPathsEnum.REPORTS);
  }

  public navigateConfiguration(): MenuPathsEnum {
    return MenuPathsEnum.COST_COMMISSIONS_FULL;
  }

  public selectConfigSubmenu(setSubmenu: boolean): void {
    this.setConfigSubmenu.emit(setSubmenu);
  }

  public toMainMenu(item: MenuItem): void {
    if (item.backToMain) {
      this.selectConfigSubmenu(false);
    }
  }
}
