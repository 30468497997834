import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-select-line',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './select-line.component.html',
  styleUrls: ['./select-line.component.scss'],
})
export class SelectLineComponent implements OnInit {
  @Input() options: string[];
  @Input() label: string;
  @Input() text: string;
  @Input() typeInput: string = 'box';
  @Input() disabled: boolean;
  @Input() error: boolean;
  @Input() public errorRequired: string;
  @Output() public readonly valueSelected: EventEmitter<string>;

  public showListItems: boolean;
  @Input() public itemSelected: string;

  constructor() {
    this.options = [];
    this.showListItems = false;
    this.label = '';
    this.error = false;
    this.valueSelected = new EventEmitter();
  }

  public onTouched = () => {};

  public onChange = (ev: any) => {};

  protected _value = '';
  protected _disable: boolean = false;

  get value() {
    return this._value;
  }

  @Input() set value(v) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  public writeValue(value: string): void {
    this.value = value;
    this.itemSelected = this.value;
  }

  public registerOnChange(fn: (data: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  public openList() {
    const box = document.getElementById(`content-pagination-table`);

    if (box?.getAttribute('show_option') === 'true') {
      box?.setAttribute('show_option', false.toString());
    } else {
      box?.setAttribute('show_option', true.toString());
    }
  }

  public getClass(): Record<string, boolean> {
    const box = document.getElementById(`content-pagination-table`);
    box?.getAttribute('show_option');
    if (box?.getAttribute('show_option') === 'true') {
      return {
        [`open`]: true,
      };
    } else {
      return {
        [`close`]: true,
      };
    }
  }

  public getClassArrow() {
    const box = document.getElementById(`content-pagination-table`);
    box?.getAttribute('show_option');
    if (box?.getAttribute('show_option') === 'true') {
      return {
        [`inverted`]: true,
      };
    } else {
      return {
        [`arrow`]: true,
      };
    }
  }

  public selectValue(value: string): void {
    if (!value || this.itemSelected === value) {
      return;
    }

    this.value = value;
    this.itemSelected = value;
    this.valueSelected.emit(this.itemSelected);
    this.showListItems = false;
    const box = document.getElementById(`content-pagination-table`);
    box?.setAttribute('show_option', false.toString());
  }
  public ngOnInit() {
    this.itemSelected = this.value;
    const box = document.getElementById(`content-pagination-table`);
    box?.setAttribute('show_option', false.toString());
    window.addEventListener('click', e => {
      const box = document.getElementById(`content-pagination-table`);
      const show_option = box?.getAttribute('show_option');
      const list = document.getElementById(`list-select-pagination`);
      if (list) {
        if (
          document
            .getElementById(`content-pagination-table`)
            ?.contains(e.target as any)
        ) {
          if (show_option === 'true') {
            list.style.visibility = 'visible';
          } else {
            list.style.visibility = 'hidden';
          }
        } else {
          if (show_option === 'true') {
            list.style.visibility = 'hidden';
            box?.setAttribute('show_option', false.toString());
          } else {
            list.style.visibility = 'visible';
          }
        }
      }
    });
  }
}
