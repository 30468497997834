import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';

import { UserPermission } from '@core/constants/user-permission.constant';
import { StateService } from '@core/services';
import { StateName } from '@shared/enums';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  private readonly stateService = inject(StateService);
  private readonly templateRef = inject(TemplateRef<unknown>);
  private readonly viewContainer = inject(ViewContainerRef);

  private subscription: Subscription = new Subscription();

  @Input('hasPermission') roles: UserPermission[] = [];

  ngOnInit(): void {
    this.subscription = this.stateService
      .state<UserPermission[]>(StateName.user_permissions)
      .subscribe(user_permissions => {
        if (!this.roles.length) {
          this.addTemplate();
        } else {
          const hasPermission = this.roles.some(role =>
            user_permissions.includes(role),
          );
          hasPermission ? this.addTemplate() : this.clearTemplate();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private addTemplate() {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private clearTemplate() {
    this.viewContainer.clear();
  }
}
