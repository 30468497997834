import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@core/services';
import { StateService } from '@core/services/state';
import { CHARACTER_QUESTION } from '@shared/constants';
import { PathTitle, StateName } from '@shared/enums';
import { MenuPathsEnum } from '@shared/enums/routing-paths.enum';
import { Settings } from '@shared/interfaces';
import { AuthenticatedUser } from '@shared/models';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public menuOptions: Settings[] = [
    {
      name: 'NAVBAR-HEADER.MENU_OPTIONS.LOG_OUT',
      icon: 'assets/images/logout-icon.svg',
      width: '14',
    },
  ];

  public titleHeader: string;
  public showHamburgerMenu: boolean;
  public user: AuthenticatedUser;
  public closeAside: boolean = false;

  private destroy$ = new Subject();

  public constructor(
    public router: Router,
    private authService: AuthService,
    private stateService: StateService,
  ) {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        let url: string = event.url;
        const indexOfqueryParams: number = url.indexOf(CHARACTER_QUESTION);
        if (indexOfqueryParams != -1) {
          url = url.slice(0, indexOfqueryParams);
        }
        this.titleHeader = PathTitle[url];
      }
    });
  }

  public get prefixName(): string {
    return `${this.user?.username?.charAt(0)?.toUpperCase()}`;
  }

  public ngOnInit() {
    this.getUserData();

    const box = document.getElementById(`content-header`);
    box?.setAttribute('show_option', false.toString());
    window.addEventListener('click', e => {
      const box = document.getElementById(`content-header`);
      const show_option = box?.getAttribute('show_option');
      const list = document.getElementById(`list-header`);
      if (list) {
        if (
          document.getElementById(`content-header`)?.contains(e.target as any)
        ) {
          if (show_option === 'true') {
            list.style.visibility = 'visible';
          } else {
            list.style.visibility = 'hidden';
          }
        } else {
          if (show_option === 'true') {
            list.style.visibility = 'hidden';
            box?.setAttribute('show_option', false.toString());
          } else {
            list.style.visibility = 'visible';
          }
        }
      }
    });
  }

  private getUserData(): void {
    this.authService
      .getAuthenticatedUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: AuthenticatedUser) => {
        this.user = user;
      });
  }

  public asideAction(): void {
    this.stateService.set(StateName.aside_close, !this.closeAside);
  }

  public openList() {
    const box = document.getElementById(`content-header`);

    if (box?.getAttribute('show_option') === 'true') {
      box?.setAttribute('show_option', false.toString());
    } else {
      box?.setAttribute('show_option', true.toString());
    }
  }

  public getClass(): Record<string, boolean> {
    const box = document.getElementById(`content-header`);
    box?.getAttribute('show_option');
    if (box?.getAttribute('show_option') === 'true') {
      return {
        [`open`]: true,
      };
    } else {
      return {
        [`close`]: true,
      };
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public selectValue(menuItem): void {
    switch (menuItem) {
      case 'NAVBAR-HEADER.MENU_OPTIONS.LOG_OUT':
        this.logout();
        break;

      case 'NAVBAR-HEADER.MENU_OPTIONS.AUTHENTICATE':
        break;
      default:
        break;
    }
  }

  private async logout(): Promise<void> {
    await firstValueFrom(this.authService.logout());
  }

  public navigateToHome(): void {
    const userRole = this.user?.roles;
    this.authService.goToHome(userRole[0]);
  }

  public openHamburgerMenu(): void {
    this.showHamburgerMenu = !this.showHamburgerMenu;
    this.stateService.set(
      StateName.change_hamburger_menu_state,
      this.showHamburgerMenu,
    );
  }
}
