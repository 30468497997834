import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localizeDate',
  standalone: true,
})
export class LocalizeDatePipe implements PipeTransform {
  public transform(value: string): string {
    if (value) {
      const dateString = value?.slice(0, 23);

      const date = new Date(`${dateString}-06:00`);
      return date.toISOString();
    }
    return null;
  }
}
