import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';

@Component({
  selector: 'app-select-fieldset',
  standalone: true,
  imports: [CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectFieldsetComponent),
      multi: true,
    },
  ],
  templateUrl: './select-fieldset.component.html',
  styleUrls: ['./select-fieldset.component.scss'],
})
export class SelectFieldsetComponent
  implements OnInit, ControlValueAccessor, AfterViewInit, OnChanges
{
  @Input() public options: string[];
  @Input() public label: string;
  @Input() public text: string;
  @Input() public idSelect: string;
  @Input() public searchEnabled: boolean;
  @Input() public iconSrc: string;
  @Input() public placeholder: string;
  @Input() public errorRequired: string;
  @Input() public errorPattern: string;
  @Input() public errorCompare: string;
  @Input() public errorServiceText: string;
  @Input() public errorService: boolean;
  @Input() public type: string;
  @Input() public maskCurrency: boolean;
  @Input() public disable: boolean;
  @Input() public genericError: boolean;
  @Input() public genericErrorText: string;
  @Output() public readonly valueSelected: EventEmitter<string>;

  public showListItems: boolean;
  public optionsFilter: string[];
  public inputValue: string = '';
  @Input() public itemSelected: string;

  public control!: NgControl;

  constructor(@Inject(Injector) private injector: Injector) {
    this.options = [];
    this.showListItems = false;
    this.label = '';
    this.itemSelected = '';
    this.iconSrc = '';
    this.type = 'text';
    this.label = 'label';
    this.maskCurrency = false;
    this.disable = false;
    this.placeholder = '';
    this.errorService = false;
    this.valueSelected = new EventEmitter();
  }

  public onTouched = () => {};

  public onChange = (ev: any) => {};

  protected _value = '';
  protected _disable: boolean = false;

  get value() {
    return this._value;
  }

  public ngAfterViewInit(): void {
    const mySearchOption = document.getElementById(
      `mySearchOption-fieldset-${this.idSelect}`,
    );

    if (mySearchOption) {
      const inputHandler = event => this.searchOptionFilter(event);
      mySearchOption.addEventListener('input', inputHandler);
      mySearchOption.addEventListener('propertychange', inputHandler);
    }
  }

  public ngOnChanges(): void {
    this.optionsFilter = JSON.parse(JSON.stringify(this.options));
  }

  public ngOnInit() {
    this.control = this.injector.get(NgControl);
    this.optionsFilter = JSON.parse(JSON.stringify(this.options));
    this.itemSelected = this.value;
    const box = document.getElementById(
      `content-select-fieldset-${this.idSelect}`,
    );
    box?.setAttribute('show_option', false.toString());
    window.addEventListener('click', e => {
      const box = document.getElementById(
        `content-select-fieldset-${this.idSelect}`,
      );
      const show_option = box?.getAttribute('show_option');
      const list = document.getElementById(
        `list-select-fieldset-${this.idSelect}`,
      );
      if (list) {
        if (
          document
            .getElementById(`content-select-fieldset-${this.idSelect}`)
            ?.contains(e.target as any)
        ) {
          if (show_option === 'true') {
            list.style.visibility = 'visible';
          } else {
            list.style.visibility = 'hidden';
          }
        } else {
          if (show_option === 'true') {
            list.style.visibility = 'hidden';
            box?.setAttribute('show_option', false.toString());
          } else {
            list.style.visibility = 'visible';
          }
        }
      }
    });
  }

  /** value setter */
  @Input() set value(v) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  public writeValue(value: string): void {
    this.value = value;
    this.itemSelected = this.value;
  }

  public registerOnChange(fn: (data: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public openList() {
    const box = document.getElementById(
      `content-select-fieldset-${this.idSelect}`,
    );

    if (box?.getAttribute('show_option') === 'true') {
      box?.setAttribute('show_option', false.toString());
    } else {
      box?.setAttribute('show_option', true.toString());
    }
  }

  public getClass() {
    const box = document.getElementById(
      `content-select-fieldset-${this.idSelect}`,
    );
    box?.getAttribute('show_option');
    if (box?.getAttribute('show_option') === 'true') {
      return {
        [`open`]: true,
      };
    } else {
      return {
        [`close`]: true,
      };
    }
  }

  public getClassArrow() {
    const box = document.getElementById(
      `content-select-fieldset-${this.idSelect}`,
    );
    box?.getAttribute('show_option');
    if (box?.getAttribute('show_option') === 'true') {
      return {
        [`inverted`]: true,
      };
    } else {
      return {
        [``]: true,
      };
    }
  }

  public selectValue(value: string) {
    const box = document.getElementById(
      `content-select-fieldset-${this.idSelect}`,
    );

    if (!value || this.itemSelected === value) {
      return;
    }
    this.inputValue = this.value;

    this.value = value;
    this.itemSelected = value;
    this.valueSelected.emit(this.itemSelected);
    box?.setAttribute('show_option', false.toString());
    this.inputValue = '';
    if (this.searchEnabled) {
      this.optionsFilter = JSON.parse(JSON.stringify(this.options));
    }
  }

  public searchOptionFilter(event) {
    this.inputValue = event.target.value;
    const searchOptions = this.options?.filter((option: string) =>
      option
        .toLocaleLowerCase()
        .includes(event.target.value.toLocaleLowerCase()),
    );

    this.optionsFilter = [...searchOptions];
  }

  public setClassInput() {
    return {
      [`disable-input`]: this.disable,
    };
  }

  public onBlur(): void {
    this.onTouched();
  }

  public get inputRequired(): boolean {
    return (
      this.control.touched &&
      this.control.hasError('required') &&
      this.control.value?.length <= 0
    );
  }

  public get validateMaxLengthAndMinLength(): boolean {
    return (
      this.control.touched &&
      (this.control.hasError('maxlength') || this.control.hasError('minlength'))
    );
  }

  public get validatePattern(): boolean {
    return this.control.touched && this.control.hasError('pattern');
  }

  public get classErrorInput(): boolean {
    return (
      (this.control.touched && this.control.invalid) ||
      this.errorService ||
      this.genericError
    );
  }
}
