import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpStatusCode,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from '@core/services';
import { MenuPathsEnum, StorageKey } from '@shared/enums';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private storageService: StorageService,
  ) {}

  public intercept(
    request: any,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          this.storageService.remove(StorageKey.ACCESS_TOKEN);
          this.router.navigateByUrl(MenuPathsEnum.LOGIN_FULL);
        }
        return throwError(() => error);
      }),
    );
  }
}
