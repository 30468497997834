<header>
  <div class="logo">
    <img
      (click)="navigateToHome()"
      class="header-logo"
      src="assets/images/logo-secondary.svg"
      alt="logo-header" />
  </div>
  <div class="hamburger-menu">
    <img
      (click)="openHamburgerMenu()"
      src="assets/images/hamburger-icon.svg"
      alt="avatar" />
  </div>
  <div class="profile" (click)="openList()">
    <div id="content-header" class="settings">
      <img class="profile-img" [src]="user.image" alt="avatar" />
      <div class="user">
        <p>{{ user.name }}</p>
      </div>
      <div class="arrow-section">
        <img
          src="assets/images/arrow-drop-down.svg"
          alt="settings"
          class="arrow" />
        <ul id="list-header" [ngClass]="getClass()">
          <li *ngFor="let opt of menuOptions" (click)="selectValue(opt.name)">
            <span>{{ opt.name | translate }}</span>
            <img class="icon-options" [src]="opt.icon" alt="icon" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
