import { Injectable } from '@angular/core';
import { StorageKey } from 'src/app/shared/enums/storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public save(key: StorageKey, values: object | string): void {
    const data: string =
      typeof values === 'object' ? JSON.stringify(values) : values;
    localStorage.setItem(key, data);
  }

  public get(key: StorageKey): any | null {
    const data: any = localStorage.getItem(key);
    return typeof data === 'object' ? JSON.parse(data) : data;
  }

  public remove(key: StorageKey): void {
    localStorage.removeItem(key);
  }
}
