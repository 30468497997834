export const JOBS = [
  'LAWYER',
  'BUSINESSMAN',
  'COUNTER',
  'UNEMPLOYED',
  'PRIVATE_SECTOR_EMPLOYEE',
  'PUBLIC_SECTOR_EMPLOYEE',
  'STUDENT',
  'RETIRED',
  'POLICE',
  'INDEPENDENT_PROFESSIONAL',
];
