<button
  id="content-button-select-{{ idSelect }}"
  [ngClass]="setPrincipalClassButtonSelect()"
  (click)="clickButton()"
  [type]="type">
  <div [ngClass]="setClassButtonSelect()" id="button">
    <div *ngIf="ico && !loading" class="item">
      <img [src]="ico" class="img-principal" alt="button-ico" />
    </div>
    <div *ngIf="!loading">
      <p>{{ txtButton | translate }}</p>
    </div>
    <div *ngIf="loading" class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <ul id="list-select-{{ idSelect }}" [ngClass]="getClass()">
    <li *ngFor="let opt of options" (click)="opt.callBack()">
      <span>{{ opt.text | translate }}</span>
    </li>
  </ul>
</button>
