import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { StateService } from '@core/services';
import { StateName } from '@shared/enums';
import { UserRole } from '@core/constants/user-role.constant';
import { Router } from '@angular/router';

export function hasRoleGuard(rolesAllowed: UserRole[]): CanMatchFn {
  return (): boolean => {
    const router = inject(Router);
    const stateService = inject(StateService);
    const userRoles = stateService.get<UserRole[]>(StateName.user_roles) || [];

    const hasUserRole = userRoles.some(userRoles =>
      rolesAllowed.includes(userRoles),
    );

    return hasUserRole;
  };
}
