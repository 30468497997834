import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CostumerDocumentsComponent } from './costumer-documents.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';

@NgModule({
  declarations: [CostumerDocumentsComponent],
  entryComponents: [CostumerDocumentsComponent],
  providers: [TranslatePipe],
  imports: [CommonModule, SharedModule],
  exports: [CostumerDocumentsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CostumerDocumentsModalModule {}
