import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validators,
} from '@angular/forms';
import { ngxMaskPatterns } from '@shared/constants';
import { LibraryModule } from '@shared/libraries/library.module';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [CommonModule, FormsModule, LibraryModule],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  @ViewChild('inputPassword') public inputPassword: ElementRef | undefined;

  @Input() public type: string;
  @Input() public inputMask: string;
  @Input() public label: string;
  @Input() public srcIcon: string;
  @Input() public errorRequired: string;
  @Input() public errorPattern: string;
  @Input() public errorCompare: string;
  @Input() public errorServiceText: string;
  @Input() public placeholder: string;
  @Input() public autocomplete: boolean | string;
  @Input() public inputLine: boolean;
  @Input() public maskCurrency: boolean;
  @Input() public disable: boolean;
  @Input() public errorService: boolean;
  @Input() public genericError: boolean;
  @Input() public genericErrorText: string;
  @Input() public errorMinMax: string;
  @Input() public acceptFiles: string;
  @Output() public readonly changePdf: EventEmitter<Event> = new EventEmitter();
  @Input() public inputStyle: string = 'wrapper';

  public control!: NgControl;

  public iconPassword: string;
  public patterns = ngxMaskPatterns;

  constructor(@Inject(Injector) private injector: Injector) {
    this.type = 'text';
    this.label = 'label';
    this.maskCurrency = false;
    this.srcIcon = '';
    this.disable = false;
    this.inputLine = false;
    this.iconPassword = 'assets/images/icon-eye.svg';
    this.placeholder = '';
    this.errorService = false;
    this.autocomplete = true;
    this.acceptFiles = '.pdf';
  }

  public ngOnInit(): void {
    this.control = this.injector.get(NgControl);
  }

  public ngAfterViewInit(): void {
    const isRequired = this.control?.control?.hasValidator(Validators.required);
    if (isRequired) {
      this.label = `${this.label} *`;
    }
  }

  public onTouched: Function = () => {};

  public onChange: Function = (ev: any) => {};

  protected _value = '';

  public get value() {
    return this._value;
  }

  public get invalid(): boolean {
    return false;
  }

  public get touched(): boolean {
    return true;
  }

  /** value setter */
  @Input() set value(v) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  public onBlur(): void {
    this.onTouched();
  }

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: (data: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public showPassword(): void {
    const input = this.inputPassword?.nativeElement?.attributes as NamedNodeMap;
    if (input.getNamedItem('type')?.value === 'password') {
      this.inputPassword?.nativeElement?.setAttribute('type', 'text');
      this.iconPassword = 'assets/images/icon-eye-off.svg';
      return;
    }

    this.inputPassword?.nativeElement?.setAttribute('type', 'password');
    this.iconPassword = 'assets/images/icon-eye.svg';
  }

  public setClassInput() {
    return {
      [`disable-input`]: this.disable,
    };
  }

  emmitpdf(event) {
    this.changePdf.emit(event);
  }

  public get inputRequired(): boolean {
    return (
      this.control.touched &&
      this.control.hasError('required') &&
      this.control.value?.length <= 0
    );
  }

  public get validateMaxLengthAndMinLength(): boolean {
    return (
      this.control.touched &&
      (this.control.hasError('maxlength') || this.control.hasError('minlength'))
    );
  }

  public get validatePattern(): boolean {
    return this.control.touched && this.control.hasError('pattern');
  }

  public get validateCompare(): boolean {
    return this.control.hasError('compare') && this.control.value?.length > 0;
  }

  public get validateMinAndMax(): boolean {
    return (
      this.control.touched &&
      (this.control.hasError('min') || this.control.hasError('max'))
    );
  }

  public get classErrorInput(): boolean {
    return (
      (this.control.touched && this.control.invalid) ||
      this.errorService ||
      this.genericError
    );
  }

  public get mask(): string {
    if (this.inputMask && this.type === 'text') {
      return this.inputMask;
    }
    return undefined;
  }
}
