export const ACTIVE = ['Accepted', 'Active'];
export const PENDING = ['Created', 'In Progress', 'Timeout'];
export const INACTIVE = [
  'Rejected',
  'Archived',
  'Closed',
  'Dormant',
  'Blocked',
  'Inactive',
];

export enum UserStatus {
  CREATED = 'Created',
  IN_PROGRESS = 'In Progress',
  TIMEOUT = 'Timeout',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  ARCHIVED = 'Archived',
  CLOSED = 'Closed',
  DORMANT = 'Dormant',
  BLOCKED = 'Blocked',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
