import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonSelect } from '@shared/interfaces/button-select.interface';

@Component({
  selector: 'app-button-select',
  styleUrls: ['./button-select.component.scss'],
  templateUrl: './button-select.component.html',
})
export class ButtonSelectComponent implements OnInit {
  @Input()
  public txtButton: string;
  @Input()
  public disabled = false;
  @Input()
  public loading = false;
  @Input()
  public ico: string;
  @Input()
  public options: ButtonSelect[];
  @Input()
  public idSelect: string;

  @Input()
  public color: string;

  @Input() public type: string = 'button';

  @Output() buttonClick: EventEmitter<void> = new EventEmitter();

  constructor() {
    this.disabled = false;
    this.color = 'black';
  }

  public ngOnInit(): void {
    const box = document.getElementById(
      `content-button-select-${this.idSelect}`,
    );
    box?.setAttribute('show_option_buttons', false.toString());
    window.addEventListener('click', e => {
      const box = document.getElementById(
        `content-button-select-${this.idSelect}`,
      );
      const show_option = box?.getAttribute('show_option_buttons');
      const list = document.getElementById(`list-select-${this.idSelect}`);
      if (list) {
        if (
          document
            .getElementById(`content-button-select-${this.idSelect}`)
            ?.contains(e.target as any)
        ) {
          if (show_option === 'true') {
            list.style.visibility = 'visible';
          } else {
            list.style.visibility = 'hidden';
          }
        } else {
          if (show_option === 'true') {
            list.style.visibility = 'hidden';
            box?.setAttribute('show_option_buttons', false.toString());
          } else {
            list.style.visibility = 'visible';
          }
        }
      }
    });
  }

  public clickButton(): void {
    if (this.options) {
      const box = document.getElementById(
        `content-button-select-${this.idSelect}`,
      );

      if (box?.getAttribute('show_option_buttons') === 'true') {
        box?.setAttribute('show_option_buttons', false.toString());
      } else {
        box?.setAttribute('show_option_buttons', true.toString());
      }
    } else {
      this.buttonClick.emit();
    }
  }

  public getClass(): Record<string, boolean> {
    const box = document.getElementById(
      `content-button-select-${this.idSelect}`,
    );
    box?.getAttribute('show_option_buttons');
    if (box?.getAttribute('show_option_buttons') === 'true') {
      return {
        [`open-button-select`]: true,
      };
    } else {
      return {
        [`close-button-select`]: true,
      };
    }
  }

  public setClassButtonSelect() {
    return {
      ['button-select__content']: true,
      [`button-select-disabled`]: this.disabled,
      [`button-select-color__${this.color}`]: true,
    };
  }

  public setPrincipalClassButtonSelect() {
    return {
      ['button-select']: true,
      ['button-select-disabled']: this.disabled,
    };
  }
}
