<div [class]="inputStyle">
  <div class="container_input">
    <img *ngIf="srcIcon" [src]="srcIcon" alt="image" />
    <div class="container_input-item">
      <fieldset
        [class.error_input]="
          (control.touched && control.invalid) || errorService || genericError
        "
        [class.disable_input]="disable">
        <legend
          [class.error_label]="
            (control.touched && control.invalid) || errorService || genericError
          "
          [class.disable_label]="disable">
          {{ label }}
        </legend>
        <input
          [(ngModel)]="value"
          [type]="type"
          [disabled]="disable"
          (blur)="onBlur()"
          (keydown.arrowup)="(false)"
          (keydown.arrowdown)="(false)"
          [autocomplete]="autocomplete"
          [class.not_icon]="!srcIcon"
          [placeholder]="placeholder"
          [ngClass]="setClassInput()"
          class="input-component"
          [accept]="acceptFiles"
          (change)="emmitpdf($event)"
          #inputPassword
          [class.disable_label]="disable"
          [mask]="mask"
          [patterns]="patterns" />
      </fieldset>
    </div>
    <img
      *ngIf="type === 'password'"
      [src]="iconPassword"
      alt="password"
      (click)="showPassword()" />
  </div>

  <span
    *ngIf="
      control.touched &&
      control.hasError('required') &&
      control.value?.length <= 0
    ">
    {{ errorRequired }}
  </span>
  <span
    *ngIf="
      control.touched &&
      (control.hasError('maxlength') || control.hasError('minlength'))
    ">
    {{ errorRequired }}
  </span>
  <span *ngIf="control.touched && control.hasError('pattern')">
    {{ errorPattern }}
  </span>

  <span *ngIf="control.hasError('compare') && control.value?.length > 0">
    {{ errorCompare }}
  </span>

  <span
    *ngIf="
      control.touched && (control.hasError('min') || control.hasError('max'))
    ">
    {{ errorMinMax }}
  </span>

  <span id="span-error" *ngIf="genericError"> {{ genericErrorText }} </span>
</div>
