import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransactionsService } from '@core/services';
import { Transaction } from '@shared/models';
import { Subject, takeUntil } from 'rxjs';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { PAGADO, PENDIENTE, DEVUELTO } from '@shared/constants/status.contants';

@Component({
  selector: 'app-transaction-details-modal',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
})
export class TransactionDetailsModalComponent implements OnDestroy {
  public form: FormGroup;
  public save: boolean;
  public loading;
  private destroy$: Subject<void> = new Subject<void>();
  @Input() public data: {
    transaction: Transaction;
  };
  @Output() public readonly closed: EventEmitter<boolean> = new EventEmitter();

  public constructor(
    private formBuilder: FormBuilder,
    private transactionService: TransactionsService,
    private readonly translateService: TranslateService,
  ) {
    this.save = false;
    this.form = this.formBuilder.group({
      udis: [null, Validators.compose([Validators.required])],
      password: [Validators.compose([Validators.required])],
    });
  }

  public close(callService: boolean = false): void {
    this.closed.emit(callService);
    this.closed.complete();
  }

  public get total(): string | number {
    return Number(this.data.transaction.total);
  }
  public downloadPDF(): void {
    this.loading = true;
    this.transactionService
      .downloadPDFFile(this.data.transaction.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        const blob = new Blob([res.body as BlobPart], {
          type: 'application/pdf',
        });
        this.loading = false;
        saveAs(
          blob,
          `${this.translateService.instant('FILES.TRANSACTION')}${
            this.data.transaction.id
          }.pdf`,
        );
      });
  }
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getStatus(status) {
    if (PAGADO.includes(status)) {
      return {
        label: 'TRANSACTION_TYPES.STATUS.PAGADO',
        color: 'green',
      };
    } else if (PENDIENTE.includes(status)) {
      return {
        label: 'TRANSACTION_TYPES.STATUS.PENDIENTE',
        color: 'orange',
      };
    } else if (DEVUELTO.includes(status)) {
      return {
        label: 'TRANSACTION_TYPES.STATUS.DEVUELTO',
        color: 'red',
      };
    } else {
      return {
        label: 'TRANSACTION_TYPES.STATUS.CANCELADO',
        color: 'red',
      };
    }
  }
}
