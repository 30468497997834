import { NgModule } from '@angular/core';
import { ChangeUdisModalModule } from './change-udis/change-udis.module';
import { UpdateUserModalModule } from './update-user/update-user.module';
import { TransactionDetailsModalModule } from './transaction-details/transaction-details.module';
import { CostumerDocumentsModalModule } from './costumer-documents/costumer-documents.module';
import { CardDetailsModalModule } from '@core/modals/card-details/card-details.module';

@NgModule({
  imports: [
    ChangeUdisModalModule,
    UpdateUserModalModule,
    TransactionDetailsModalModule,
    CostumerDocumentsModalModule,
    CardDetailsModalModule,
  ],
})
export class ModalsModule {}
