<nav class="navbar">
  <div class="last-update">
    <div *ngIf="showUpdateText" class="circle"></div>
    <span *ngIf="showUpdateText"
      >{{ "TABLE.PAGINATE.UPDATE" | translate }}</span
    >
  </div>

  <ul class="pagination">
    <li
      *ngIf="totalElements"
      [ngClass]="page === 1 ? 'disabled' : null"
      (click)="selectPage(page - 1)">
      <img
        class="arrow pagination__arrow-left icon"
        src="assets/images/arrow-table.svg"
        alt="back" />
    </li>
    <p class="pages">
      {{ page }} {{ "TABLE.PAGINATE.OF" | translate }} {{ totalPages }}
    </p>
    <li
      *ngIf="totalElements"
      [ngClass]="page === totalPages ? 'disabled' : null"
      (click)="selectPage(page + 1)">
      <img class="arrow icon" src="assets/images/arrow-table.svg" alt="next" />
    </li>
  </ul>

  <div class="results">
    <label class="pages"
      >{{ "TABLE.PAGINATE.RESULTS_PER_PAGE" | translate }}</label
    >
    <div class="select">
      <app-select-line
        [value]="itemsPerPage?.toString() || '3'"
        [options]="pages"
        typeSelect="light"
        [label]="''"
        (valueSelected)="selectItemsPerPage($event)"
        [itemSelected]="itemsPerPage?.toString() || '3'">
      </app-select-line>
    </div>
  </div>
</nav>
