import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationService } from '@core/services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-change-udis-modal',
  templateUrl: './change-udis.component.html',
  styleUrls: ['./change-udis.component.scss'],
})
export class ChangeUdisModalComponent implements OnDestroy {
  public form: FormGroup;
  public save: boolean;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  @Output() public readonly closed: EventEmitter<boolean> = new EventEmitter();
  @Input() public data;
  public loading = false;
  public constructor(
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
  ) {
    this.save = false;
    this.form = this.formBuilder.group({
      udis: [null, Validators.compose([Validators.required])],
      password_authorize: [null, Validators.compose([Validators.required])],
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public saveUdiWithPassword(): void {
    if (this.form.get('password_authorize').invalid) {
      this.form.get('password_authorize').markAsTouched();
      return;
    }
    this.loading = true;
    const body = {
      value: Number(this.form.get('udis').value),
    };
    const password = this.form.get('password_authorize').value;
    this.configurationService
      .putUdis(this.data.udi.id, body, password)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.loading = false;
          this.close(true);
        },
        error: () => {
          this.loading = false;
        },
        complete: () => {
          this.loading = true;
          this.close(true);
        },
      });
  }

  public close(callService: boolean = false): void {
    this.closed.emit(callService);
    this.closed.complete();
  }

  public saveUdi(): void {
    if (this.form.get('udis').invalid) {
      this.form.get('udis').markAsTouched();
      return;
    }
    this.save = true;
  }
}
