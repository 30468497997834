import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { catchError, map, of, switchMap } from 'rxjs';
import { MenuPathsEnum, StateName, StorageKey } from 'src/app/shared/enums';
import { StateService, StorageService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private readonly stateService: StateService,
    private readonly router: Router,
    private readonly storageService: StorageService,
  ) {}

  public canActivate() {
    const EXIST_TOKEN = this.storageService.get(StorageKey.ACCESS_TOKEN);
    if (EXIST_TOKEN) {
      return this.stateService.state(StateName.user_authenticated).pipe(
        map(user => {
          if (user) {
            return true;
          }
          this.router.navigateByUrl(MenuPathsEnum.LOGIN_FULL);
          return false;
        }),
      );
    }

    this.router.navigateByUrl(MenuPathsEnum.LOGIN_FULL);
    return of(false);
  }
}
