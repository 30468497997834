<section class="breadcrumb">
  <div class="breadcrumb__header">
    <div class="breadcrumb__header__title">
      <img *ngIf="ico" [src]="ico" alt="breadcrumb-logo" />
      <h2
        [ngClass]="{ link: item.redirect }"
        class="breadcrumb__header__title__text"
        [routerLink]="item.redirect"
        *ngFor="let item of url; let i = index">
        {{ item.name }}<span *ngIf="i < url.length - 1"> /&nbsp;</span>
      </h2>
    </div>
  </div>
</section>
